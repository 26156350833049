export const SIGN_IN = "SIGN_IN";
export const SIGN_OUT = "SIGN_OUT";
export const SHOW_AUTH_ERROR = "SHOW_AUTH_ERROR";
export const GET_CATALOG = "GET_CATALOG";
export const GET_CATALOGS = "GET_CATALOGS";
export const GET_LATEST_CATALOG = "GET_LATEST_CATALOG";
export const GET_TARGET_CATEGORY = "GET_TARGET_CATEGORY";
export const GET_TARGET_CATEGORY_SUMMARIES = "GET_TARGET_CATEGORY_SUMMARIES";
export const GET_FILTERED_TARGET_CATEGORY_SUMMARIES =
  "GET_FILTERED_TARGET_CATEGORY_SUMMARIES";
export const GET_AHFS_CATEGORIES = "GET_AHFS_CATEGORIES";
export const GET_TARGET_GPIS = "GET_TARGET_GPIS";
export const GET_FILTERED_TARGET_GPIS = "GET_FILTERED_TARGET_GPIS";
export const GET_EQGROUPS = "GET_EQGROUPS";
export const GET_EQDRUGS = "GET_EQDRUGS";
export const GET_CLIENT = "GET_CLIENT";
export const GET_CLIENTS = "GET_CLIENTS";
export const SAVE_CLIENT = "SAVE_CLIENT";
export const EDIT_CLIENT = "EDIT_CLIENT";
export const SELECT_CLIENT = "SELECT_CLIENT";
export const DELETE_CLIENT = "DELETE_CLIENT";
export const SAVE_CONTRACT = "SAVE_CONTRACT";
export const EDIT_CONTRACT = "EDIT_CONTRACT";
export const GET_CONTRACT = "GET_CONTRACT";
export const DELETE_CONTRACT = "DELETE_CONTRACT";
export const CLEAR_SELECTED_CONTRACT = "CLEAR_SELECTED_CONTRACT";
export const GET_LINES_OF_BUSINESS = "GET_LINES_OF_BUSINESS";
export const GET_CONFIG_FILES = "GET_CONFIG_FILES";
export const GET_CONFIG_FILES_SORTED = "GET_CONFIG_FILES_SORTED";
export const GET_CONFIG_FILE = "GET_CONFIG_FILE";
export const CLEAR_CONFIG_FILES = "CLEAR_CONFIG_FILES";
export const GET_AWPD_FILE_CONTENT = "GET_AWPD_FILE_CONTENT";
export const CLEAR_FILE_CONTENT = "CLEAR_FILE_CONTENT";
export const DELETE_CONFIG_FILE = "DELETE_CONFIG_FILE";
export const GET_MAC_FILE_CONTENT = "GET_MAC_FILE_CONTENT";
export const UPLOAD_CONFIG_FILE = "UPLOAD_CONFIG_FILE";
export const GET_FORM_FILE_CONTENT = "GET_FORM_FILE_CONTENT";
export const GET_BC_FILE_CONTENT = "GET_BC_FILE_CONTENT";
export const GET_GP_FILE_CONTENT = "GET_GP_FILE_CONTENT";
export const GENERATE_FORMULARY_CONTENT = "GENERATE_FORMULARY_CONTENT";
export const GET_CLIENT_GROUPS = "GET_CLIENT_GROUPS";
export const CLEAR_CLIENT_GROUPS = "CLEAR_CLIENT_GROUPS";
export const ADD_SORT_ORDER = "ADD_SORT_ORDER";
export const RESET_SORT_ORDER = "RESET_SORT_ORDER";
export const SET_SEARCH_TERM = "SET_SEARCH_TERM";
export const TOGGLE_SIDEBAR_VIS = "TOGGLE_SIDEBAR_VIS";
export const POPULATE_SIDEBAR = "POPULATE_SIDEBAR";
export const POPULATE_BREADCRUMBS = "POPULATE_BREADCRUMBS";
export const CLEAR_BREADCRUMBS = "CLEAR_BREADCRUMBS";
export const GET_BENEFITS = "GET_BENEFITS";
export const GET_BENEFIT = "GET_BENEFIT";
export const EDIT_BENEFIT = "EDIT_BENEFIT";
export const SAVE_BENEFIT = "SAVE_BENEFIT";
export const DELETE_BENEFIT = "DELETE_BENEFIT";
export const CLEAR_BENEFITS = "CLEAR_BENEFITS";
export const BENEFITS_LOADED = "BENEFITS_LOADED";
export const GET_AHFS_GPIS = "GET_AHFS_GPIS";
export const GET_AHFS_DRUG_DESCRIPTORS = "GET_AHFS_DRUG_DESCRIPTORS";
export const CLEAR_AHFS_DRUG_DESCRIPTORS = "CLEAR_AHFS_DRUG_DESCRIPTORS";
export const SAVE_TARGET_GPI = "SAVE_TARGET_GPI";
export const GET_WEBPAGE_PROPERTIES = "GET_WEBPAGE_PROPERTIES";
export const CLEAR_TARGET_CATEGORY = "CLEAR_TARGET_CATEGORY";
export const CLEAR_TARGET_CATEGORIES = "CLEAR_TARGET_CATEGORIES";
export const GET_MPDS = "GET_MPDS";
export const GET_MPDS_CORE_SORTED = "GET_MPDS_CORE_SORTED";
export const GET_MPD = "GET_MPD";
export const SAVE_MPDS = "SAVE_MPDS";
export const EDIT_MPD = "EDIT_MPD";
export const CLEAR_MPDS = "CLEAR_MPDS";
export const DELETE_MPD = "DELETE_MPD";
export const GET_NDC_PRICES = "GET_NDC_PRICES";
export const CLEAR_NDC_PRICES = "CLEAR_NDC_PRICES";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const CLEAR_CHANGE_PASSWORD = "CLEAR_CHANGE_PASSWORD";
export const CALCULATE_MPD = "CALCULATE_MPD";
export const CLEAR_CALCULATED_MPD = "CLEAR_CALCULATED_MPD";
export const DISPLAY_ERROR = "DISPLAY_ERROR";
export const CLEAR_ERROR = "CLEAR_ERROR";
export const GET_MPD_EXCLUSION_REASONS = "GET_MPD_EXCLUSION_REASONS";
export const GET_MPD_GPI_EXCLUSION_REASONS = "GET_MPD_GPI_EXCLUSION_REASONS";
export const EXCLUDE_MPD_CATEGORIES = "EXCLUDE_MPD_CATEGORIES";
export const EXCLUDE_MPD_GPIS = "EXCLUDE_MPD_GPIS";
export const GET_MPD_SUMMARIES = "GET_MPD_SUMMARIES";
export const CLEAR_MPD_SUMMARIES = "CLEAR_MPD_SUMMARIES";
export const UPDATE_MPD_SUMMARIES = "UPDATE_MPD_SUMMARIES";
export const GET_MPD_TIE_IN = "GET_MPD_TIE_IN";
export const GET_MPD_TIE_INS = "GET_MPD_TIE_INS";
export const SAVE_MPD_TIE_IN = "SAVE_MPD_TIE_IN";
export const EDIT_MPD_TIE_IN = "EDIT_MPD_TIE_IN";
export const DELETE_MPD_TIE_IN = "DELETE_MPD_TIE_IN";
export const CLEAR_MPD_TIE_INS = "CLEAR_MPD_TIE_INS";
export const MPDS_LOADED = "MPDS_LOADED";
export const MPDS_UNLOADED = "MPDS_UNLOADED";
export const GET_MPD_TARGET_COSTS = "GET_MPD_TARGET_COSTS";
export const SET_INITIAL_MIN_MAX_FILTER = "SET_INITIAL_MIN_MAX_FILTER";
export const UPDATE_MIN_MAX_FILTER = "UPDATE_MIN_MAX_FILTER";
export const CLEAR_ALL_FILTERS = "CLEAR_ALL_FILTERS";
export const GET_CLAIM_CORRELATIONS = "GET_CLAIM_CORRELATIONS";
export const GET_ROLL_INS = "GET_ROLL_INS";
export const CLEAR_ROLL_INS = "CLEAR_ROLL_INS";
export const TRY_CATEGORY_FILL_FACTOR_UPDATE =
  "TRY_CATEGORY_FILL_FACTOR_UPDATE";
export const TRY_CATEGORY_FILL_FACTORS_SEARCH =
  "TRY_CATEGORY_FILL_FACTORS_SEARCH";
export const TRY_GPI12_FILL_FACTORS_SEARCH = "TRY_GPI12_FILL_FACTORS_SEARCH";
export const TRY_GPI12_FILL_FACTOR_UPDATE = "TRY_GPI12_FILL_FACTOR_UPDATE";
export const GPI12_FILL_FACTORS_EXPORT = "GPI12_FILL_FACTORS_EXPORT";
export const GET_QUARTERLY_FILL_FACTORS = "GET_QUARTERLY_FILL_FACTORS";
export const GET_FILL_FACTORS_BASELINE_DATES =
  "GET_FILL_FACTORS_BASELINE_DATES";
export const GET_QUARTERLY_FILL_FACTOR_BASELINE_DATES =
  "GET_QUARTERLY_FILL_FACTOR_BASELINE_DATES";
export const GET_DRUG_PAIR_ERROR_CODES = "GET_DRUG_PAIR_ERROR_CODES";
export const HEALTH_CHECK_PASSED = "HEALTH_CHECK_PASSED";
export const HEALTH_CHECK_FAILED = "HEALTH_CHECK_FAILED";
export const GET_ELIGIBILITY_CORRELATIONS = "GET_ELIGIBILITY_CORRELATIONS";
export const GET_MPD_ACTIVITY_LOG = "GET_MPD_ACTIVITY_LOG";
export const GET_PRODUCTS = "GET_PRODUCTS";
export const SET_STACK_DETAILS = "SET_STACK_DETAILS";
export const GET_REPORT_TYPES = "GET_REPORT_TYPES";
export const TRY_CLAIM_SEARCH = "TRY_CLAIM_SEARCH";
export const CLEAR_CLAIMS = "CLEAR_CLAIMS";
export const GET_REPORT_CLAIMS_DATE_RANGE = "GET_REPORT_CLAIMS_DATE_RANGE";
export const GET_REPORTS = "GET_REPORTS";
export const GET_CAGCENTRIC_COUNTS = "GET_CAGCENTRIC_COUNTS";
export const CLEAR_REPORTS = "CLEAR_REPORTS";
export const CLEAR_CLIENTS = "CLEAR_CLIENTS";
export const SHOW_CLAIM_SEARCH_EXCEPTION = "SHOW_CLAIM_SEARCH_EXCEPTION";
export const GET_DECISION_GRIDS = "GET_DECISION_GRIDS";
export const GET_DECISION_GRID = "GET_DECISION_GRID";
export const GET_DECISION_GRID_AUDIT_LOG = "GET_DECISION_GRID_AUDIT_LOG";
export const GET_GCN_AUDIT_LOGS = "GET_GCN_AUDIT_LOGS";
export const GET_DG_STATUSES = "GET_DG_STATUSES";
export const GET_DRUG_CATEGORY = "GET_DRUG_CATEGORY";
export const GET_DRUG_CATEGORIES = "GET_DRUG_CATEGORIES";
export const GET_DRUG_CATEGORIES_AUDIT_LOG = "GET_DRUG_CATEGORIES_AUDIT_LOG";
export const CLEAR_DRUG_CATEGORY = "CLEAR_DRUG_CATEGORY";
export const TRY_DG_SEARCH = "TRY_DG_SEARCH";
export const GET_CLINICAL_IMPACT_CODES = "GET_CLINICAL_IMPACT_CODES";
export const GET_CONDITION_CODES = "GET_CONDITION_CODES";
export const GET_DRUG_PAIRING_OPTIONS = "GET_DRUG_PAIRING_OPTIONS";
export const SEARCH_DDNS = "SEARCH_DDNS";
export const SAVE_DRUG_PAIRING_OPTIONS = "SAVE_DRUG_PAIRING_OPTIONS";
export const CLEAR_DDNS = "CLEAR_DDNS";
export const CLEAR_DPOS = "CLEAR_DPOS";
export const UPDATE_DPO = "UPDATE_DPO";
export const GET_WORKBOOKS = "GET_WORKBOOKS";
export const GET_WORKBOOK = "GET_WORKBOOK";
export const GET_WORKBOOK_ENTRIES = "GET_WORKBOOK_ENTRIES";
export const CLEAR_WORKBOOK_ENTRIES = "CLEAR_WORKBOOK_ENTRIES";
export const TRY_APPROVE_WORKBOOK = "TRY_APPROVE_WORKBOOK";
export const CLEAR_APPROVE_WORKBOOK = "CLEAR_APPROVE_WORKBOOK";
export const GET_PREVIOUSLY_APPROVED_WORKBOOK =
  "GET_PREVIOUSLY_APPROVED_WORKBOOK";
export const GET_WORKBOOK_ERRORS = "GET_WORKBOOK_ERRORS";
export const CLEAR_WORKBOOK_ERRORS = "CLEAR_WORKBOOK_ERRORS";
export const TRY_WORKBOOK_SEARCH = "TRY_WORKBOOK_SEARCH";
export const TRY_WORKBOOK_ENTRY_SEARCH = "TRY_WORKBOOK_ENTRY_SEARCH";
export const TRY_WORKBOOK_ERROR_SEARCH = "TRY_WORKBOOK_ERROR_SEARCH";
export const CHECK_WORKBOOK_EXISTENCE = "CHECK_WORKBOOK_EXISTENCE";
export const GET_CATALOG_DELIVERIES = "GET_CATALOG_DELIVERIES";
export const TRY_ADDITIONAL_TARGET_DRUG_SEARCH =
  "TRY_ADDITIONAL_TARGET_DRUG_SEARCH";
export const CLEAR_ADDITIONAL_TARGET_DRUGS = "CLEAR_ADDITIONAL_TARGET_DRUGS";
export const GET_UPLOADED_ROLLINS_REPORTS = "GET_UPLOADED_ROLLINS_REPORTS";
export const UPLOAD_ROLLINS_REPORT = "UPLOAD_ROLLINS_REPORT";
export const DELETE_ROLLINS_REPORT = "DELETE_ROLLINS_REPORT";
export const TRY_PROVIDER_TARGET_SEARCH = "TRY_PROVIDER_TARGET_SEARCH";
export const ADD_ADDITIONAL_TARGET_SAVE_ERRORS =
  "ADD_ADDITIONAL_TARGET_SAVE_ERRORS";
export const GET_PRODUCT_NOTIFICATIONS = "GET_PRODUCT_NOTIFICATIONS";
export const CLEAR_PRODUCT_NOTIFICATIONS = "CLEAR_PRODUCT_NOTIFICATIONS";
export const ADD_PRODUCT_NOTIFICATION = "ADD_PRODUCT_NOTIFICATION";
export const REMOVE_PRODUCT_NOTIFICATION = "REMOVE_PRODUCT_NOTIFICATION";
export const GET_ROLLINS_REPORT_NOTIFICATIONS =
  "GET_ROLLINS_REPORT_NOTIFICATIONS";
export const CLEAR_ROLLINS_REPORT_NOTIFICATIONS =
  "CLEAR_ROLLINS_REPORT_NOTIFICATIONS";
export const GET_WEEK_OF_YEAR_LIST = "GET_WEEK_OF_YEAR_LIST";
export const CLEAR_WEEK_OF_YEAR_LIST = "CLEAR_WEEK_OF_YEAR_LIST";
export const GET_MPD_CATEGORY_EXCLUSIONS = "GET_MPD_CATEGORY_EXCLUSIONS";
export const CLEAR_MPD_CATEGORY_EXCLUSIONS = "CLEAR_MPD_CATEGORY_EXCLUSIONS";
export const GET_GLOSSARY_TERMS = "GET_GLOSSARY_TERMS";
export const CLEAR_GLOSSARY_TERMS = "CLEAR_GLOSSARY_TERMS";
export const GET_CATEGORY_REFERENCES = "GET_CATEGORY_REFERENCES";
export const GET_CATEGORY_REFERENCE = "GET_CATEGORY_REFERENCE";
export const TOGGLE_SIDEBAR_COLLAPSE = "TOGGLE_SIDEBAR_COLLAPSE";
export const GET_CLIENT_CONTRACT_PRODUCT_AUDIT_LOG =
  "GET_CLIENT_CONTRACT_PRODUCT_AUDIT_LOG";
export const CLEAR_CLIENT_CONTRACT_PRODUCT_AUDIT_LOG =
  "CLEAR_CLIENT_CONTRACT_PRODUCT_AUDIT_LOG";
export const LOADING = "LOADING";
export const GET_ADDITIONAL_TARGET_DRUGS_AUDIT_LOG =
  "GET_ADDITIONAL_TARGET_DRUGS_AUDIT_LOG";
export const CLEAR_ADDITIONAL_TARGET_DRUGS_AUDIT_LOG =
  "CLEAR_ADDITIONAL_TARGET_DRUGS_AUDIT_LOG";
export const CLEAR_FILL_FACTORS = "CLEAR_FILL_FACTORS";
export const GET_CATEGORY_REFERENCE_DRUGS_AUDIT_LOG =
  "GET_CATEGORY_REFERENCE_DRUGS_AUDIT_LOG";
export const CLEAR_CATEGORY_REFERENCE_DRUGS_AUDIT_LOG =
  "CLEAR_CATEGORY_REFERENCE_DRUGS_AUDIT_LOG";
export const GET_REBATE_REF_NDCS = "GET_REBATE_REF_NDCS";
export const GET_ESTIMATED_CLAIM_CORRELATIONS =
  "GET_ESTIMATED_CLAIM_CORRELATIONS";
export const GET_ESTIMATED_CLAIM_CORRELATION_RANGES =
  "GET_ESTIMATED_CLAIM_CORRELATION_RANGES";
export const UPLOAD_ESTIMATED_CLAIMS = "UPLOAD_ESTIMATED_CLAIMS";
export const DELETE_EST_CLAIM_CORRELATION = "DELETE_EST_CLAIM_CORRELATION";
export const CLEAR_PROSPECTIVE_REPORTS = "CLEAR_PROSPECTIVE_REPORTS";
export const GET_PROSPECTIVE_REPORTS = "GET_PROSPECTIVE_REPORTS";
export const PROSPECTIVE_REPORTS_LOADED = "PROSPECTIVE_REPORTS_LOADED";
export const GET_ALTERNATE_GPI_COUNTS = "GET_ALTERNATE_GPI_COUNTS";
export const PAIRING_WORKBOOK_APPROVAL_LOADING =
  "PAIRING_WORKBOOK_APPROVAL_LOADING";
export const SAVE_NDC_DRUG_PAIRING_OPTION = "SAVE_NDC_DRUG_PAIRING_OPTION";
export const GET_ALL_NDC_DRUG_PAIRING_OPTIONS =
  "GET_ALL_NDC_DRUG_PAIRING_OPTIONS";
export const CLEAR_NDC_DRUG_PAIRING_OPTIONS = "CLEAR_NDC_DRUG_PAIRING_OPTIONS";
export const UPDATE_NDC_DPO = "UPDATE_NDC_DPO";
export const GET_INTERNAL_CATALOG_REPORTS = "GET_INTERNAL_CATALOG_REPORTS";
