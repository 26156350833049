import React, { Component } from "react";
import { connect } from "react-redux";
import { getClinicalSidebarItems } from "../Constants";
import {
  populateBreadCrumbs,
  populateSidebar,
  tryWorkbookSearch,
  tryDeleteWorkbook,
  exportWorkbooks,
  exportAllWorkbooks,
} from "../../../actions";
import _ from "lodash";
import { Container, Row, Col, Button } from "reactstrap";
import { FaTrashAlt, FaLock } from "react-icons/fa";
import RPSDataGrid from "../../DataGrid/RPSDataGrid";
import {
  VALID_EDIT_STATUSSES,
  Role,
  userNameFormatter,
  linkFormatter,
  userNameSortHandler,
  localDateTimeFormatter,
} from "../../../utils";
import ConfirmationModal from "../../Modal/ConfirmationModal";
import WorkbookSearchForm from "./WorkbookSearchForm";

class Workbooks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currPage: 1,
      sizePerPage: 15,
      sortField: null,
      isAuthorized: _.some(this.props.roles, (role) => {
        return (
          role.name === Role.CLINICAL_APPROVER.name ||
          role.name === Role.CLINICIAN.name
        );
      }),
      isDeleteOpen: false,
      searchTerms: { names: [], statuses: [] },
      selectedWorkbooks: [],
    };
  }

  componentDidMount = () => {
    this.setupBreadCrumbs();
    this.setupSidebar();
    this.getPage();
  };

  setupBreadCrumbs = () => {
    const breadCrumbs = {
      crumbs: [{ to: "/dashboard", name: "Home", active: false }],
      finalCrumb: "Clinical Pairings Workbooks",
    };
    this.props.populateBreadCrumbs(breadCrumbs);
  };

  setupSidebar = () => {
    this.props.populateSidebar(getClinicalSidebarItems(this.props.roles));
  };

  toggleDeleteModal = (workbook) => {
    if (workbook && workbook.hasOwnProperty("name")) {
      const workbookName = workbook.name;
      this.setState({
        isDeleteOpen: !this.state.isDeleteOpen,
        dModalHeader: `Delete ${workbookName}`,
        dModalContent: `Are you sure you want to delete ${workbookName}?`,
        dModalAction: this.props.tryDeleteWorkbook,
        selectedGridId: workbook.id,
      });
    } else {
      this.setState({ isDeleteOpen: !this.state.isDeleteOpen });
    }
  };

  actionFormatter = (_, row) => {
    const status = row.status.toLowerCase();
    const draftStatus = VALID_EDIT_STATUSSES.Draft.toLowerCase();
    const activeStatus = VALID_EDIT_STATUSSES.Active.toLowerCase();
    const deletable =
      (status === draftStatus || status === activeStatus) &&
      this.state.isAuthorized;

    return (
      <>
        {deletable && (
          <Button
            className="linkBtn tableBtn actionIconBtnDanger"
            color="link"
            onClick={() => this.toggleDeleteModal(row)}
          >
            <FaTrashAlt />
          </Button>
        )}
        {!deletable && (
          <Button className="linkBtn tableBtn" color="link" disabled>
            <FaLock />
          </Button>
        )}
      </>
    );
  };

  nameFormatter = (content, row) => {
    return linkFormatter(content, `/workbooks/${row.id}`);
  };

  COLUMNS = [
    {
      dataField: "actions",
      text: "Action",
      formatter: this.actionFormatter,
      isDummyField: true,
      headerStyle: {
        width: "4%",
      },
      editable: false,
    },
    {
      dataField: "name",
      sort: true,
      text: "Clinical Pairing Workbook Name",
      headerStyle: {
        width: "50%",
      },
      formatter: this.nameFormatter,
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      headerStyle: { width: "8%" },
      headerAlign: "center",
      align: "center",
    },
    {
      dataField: "pairedDate",
      sort: true,
      formatter: localDateTimeFormatter,
      text: "Paired Date",
      headerStyle: {
        width: "8%",
      },
      headerAlign: "center",
      align: "center",
    },
    {
      dataField: "pairer",
      text: "User",
      sort: true,
      headerStyle: { width: "8%" },
      headerAlign: "center",
      formatter: userNameFormatter,
      sortFunc: userNameSortHandler,
    },
  ];

  getPage = () => {
    this.props.tryWorkbookSearch(
      this.state.searchTerms,
      this.state.currPage - 1,
      this.state.sizePerPage,
      this.state.sortField
    );
  };

  onSearchSubmit = (searchTerms) => {
    this.setState({ currPage: 1, searchTerms }, () => {
      this.getPage();
    });
  };

  onSizePerPageChange = (sizePerPage, page) => {
    this.setState({ currPage: page, sizePerPage }, () => {
      this.getPage();
    });
  };

  handleTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
    if (type === "sort") {
      // Force backend to sort by user lastname rather than ID
      if (sortField === "pairer") {
        sortField = "pairer.lastName";
      }

      sortField = {
        dataField: sortField,
        order: sortOrder,
      };
      this.setState({ sortField }, () => {
        this.getPage();
      });
      return -1;
    }

    this.setState({ currPage: page, sizePerPage: sizePerPage }, () => {
      this.getPage();
    });
  };

  getExportSelectRow = () => {
    if (this.state.isAuthorized) {
      return {
        mode: "checkbox",
        clickToSelect: false,
        headerColumnStyle: { width: "3%" },
        onSelect: this.handleOnSelect,
        onSelectAll: this.handleOnSelectAll,
      };
    }
    return;
  };

  handleOnSelect = (row, isSelect) => {
    if (isSelect) {
      this.setState(() => ({
        selectedWorkbooks: [...this.state.selectedWorkbooks, row.id],
      }));
    } else {
      this.setState(() => ({
        selectedWorkbooks: this.state.selectedWorkbooks.filter(
          (x) => x !== row.id
        ),
      }));
    }
  };

  handleOnSelectAll = (isSelect, rows) => {
    const ids = rows.map((r) => r.id);
    if (isSelect) {
      this.setState(() => ({
        selectedWorkbooks: ids,
      }));
    } else {
      this.setState(() => ({
        selectedWorkbooks: [],
      }));
    }
  };

  exportSelectedWorkbooks = () => {
    this.props.exportWorkbooks(this.state.selectedWorkbooks.join());
  };

  exportAllWorkbooks = () => {
    this.props.exportAllWorkbooks(this.state.searchTerms);
  };

  render() {
    const numSelected = this.state.selectedWorkbooks.length;

    if (_.isEmpty(this.props.workbooks)) {
      return <div>Loading...</div>;
    }

    return (
      <Container className="main-container" fluid>
        <Row>
          <Col>
            <h3>Clinical Pairings Workbooks</h3>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <WorkbookSearchForm onSubmit={this.onSearchSubmit} />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            {this.state.isAuthorized && (
              <>
                <Button
                  color="secondary"
                  disabled={
                    !this.state.selectedWorkbooks.length ||
                    !this.state.isAuthorized ||
                    this.props.exportLoading
                  }
                  onClick={this.exportSelectedWorkbooks}
                >
                  Export Selected Workbook{numSelected > 1 && "s"}&nbsp;
                  {numSelected > 0 &&
                    `(${this.state.selectedWorkbooks.length})`}
                </Button>
                <Button
                  className="ms-3"
                  color="secondary"
                  disabled={
                    !this.state.isAuthorized || this.props.exportLoading
                  }
                  onClick={this.exportAllWorkbooks}
                  title="Number of workbooks exported is determined by applied filters"
                >
                  Export All Workbooks&nbsp;
                  {`(${this.props.workbooks.totalElements})`}
                </Button>
                {this.props.exportLoading && (
                  <span className="ms-3 form-error">
                    {this.props.loadingMessage}
                  </span>
                )}
              </>
            )}
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <RPSDataGrid
              keyField="id"
              remote
              paginated
              sizePerPage={this.state.sizePerPage}
              page={this.state.currPage}
              totalSize={this.props.workbooks.totalElements}
              data={
                this.props.workbooks.content ? this.props.workbooks.content : []
              }
              columns={this.COLUMNS}
              onSizePerPageChange={this.onSizePerPageChange}
              onTableChange={this.handleTableChange}
              selectRow={this.getExportSelectRow()}
            />
          </Col>
        </Row>
        <ConfirmationModal
          isOpen={this.state.isDeleteOpen}
          toggle={this.toggleDeleteModal}
          header={this.state.dModalHeader}
          content={this.state.dModalContent}
          action={this.state.dModalAction}
          objId={this.state.selectedGridId}
        />
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    roles: state.auth.currentUser.roles,
    workbooks: state.workbooks,
    exportLoading: state.loadingStatus.loading,
    loadingMessage: state.loadingStatus.message,
  };
};

export default connect(mapStateToProps, {
  populateBreadCrumbs,
  populateSidebar,
  tryWorkbookSearch,
  tryDeleteWorkbook,
  exportWorkbooks,
  exportAllWorkbooks,
})(Workbooks);
