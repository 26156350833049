import React, { Component } from "react";
import { connect } from "react-redux";
import { getClinicalSidebarItems } from "../Constants";
import {
  populateBreadCrumbs,
  populateSidebar,
  searchDrugPairingOptions,
  getDecisionGrid,
  existenceCheck,
  tryPairWorkbook,
  deleteDPO,
  clearDPOS,
  copyDPO,
  updateDPO,
  getNDCPricesAction,
  clearNdcPrices,
  saveNDCDPO,
  hasValidNDCs,
} from "../../../actions";
import _, { isNull } from "lodash";
import { Container, Row, Col, Button, Input, FormGroup } from "reactstrap";
import RPSDataGrid from "../../DataGrid/RPSDataGrid";
import cellEditFactory from "react-bootstrap-table2-editor";
import {
  authCheck,
  fullYesNoFormatter,
  getDemoColumnHeader,
  isDemoUser,
  obfuscationFormatter,
  OBFUSCATION_COLUMNS,
  renderTextArea,
  required,
  Role,
  VALID_EDIT_STATUSSES,
  COLUMN_LEGEND,
  HEADER_STYLES,
  minimumFillFactorValueFormatter,
  minimumValues,
  MIN_ONE_TIP,
  NO_MIN_TIP,
} from "../../../utils";
import { Link } from "react-router-dom";
import DPOSearchForm from "./DPOSearchForm";
import ConfirmationModal from "../../Modal/ConfirmationModal";
import "./DecisionGrids.css";
import {
  floatMinGreater0Max99999MaxDec3Req,
  intMin1Max999Req,
  intMin1Max99Req,
  floatMin1Max99999MaxDec3Req,
} from "./editValidators";
import NDCPriceModal from "../../Modal/NDCPriceModal";
import FormModal from "../../Modal/FormModal";
import { Field } from "redux-form";
import history from "../../../history";
import LoadingModal from "../../Modal/LoadingModal";
import ActionButton from "../../DataGrid/ActionButton";
import NDCExpandRow from "./NDCExpandRow";

class DecisionGridView extends Component {
  constructor(props) {
    super(props);
    const tabOptions = [
      { label: "Target Only", value: "TARGET" },
      { label: "Alternative Only", value: "ALTERNATIVE" },
      { label: "Both", value: "BOTH" },
      { label: "NA", value: "NA" },
    ];

    const adminTypeOptions = [
      { label: "Provider", value: "PROVIDER" },
      { label: "Self", value: "SELF" },
      { label: "Blank", value: "removeAdminType" },
    ];

    const yesNoOptions = [
      { label: "Yes", value: "Y" },
      { label: "No", value: "N" },
    ];

    // This will contain a 3rd option, Yes - NDC when NDC pairing work is added
    const includeOptions = [
      { label: "Yes - GPI", value: "Y" },
      { label: "Yes - NDC", value: "YNDC" },
      { label: "No", value: "N" },
    ];

    const minValOptions = _.filter(minimumValues, (val) => {
      return val.value !== "PER_GPI";
    });

    this.state = {
      currPage: 1,
      sizePerPage: 15,
      sortField: null,
      isDemoUser: isDemoUser(this.props.roles),
      isAuthorized:
        authCheck(this.props.roles, Role.CLINICAL_APPROVER.name) ||
        authCheck(this.props.roles, Role.CLINICIAN.name),
      dgId: this.props.match.params.dgId,
      breadcrumbsLoaded: false,
      existenceChecked: false,
      searchTerms: {
        drugNames: [],
        pairingCodes: [],
        gpis: [],
        dacons: [],
        des: [],
        includeOptions: [],
      },
      isCopyOpen: false,
      isDeleteOpen: false,
      isDeleteAllNDCDPOsOpen: false,
      tabOptions,
      adminTypeOptions,
      isNoteModalOpen: false,
      noteModalDpo: null,
      noteModalHeader: null,
      noteModalCurrentNote: null,
      isExistingWBOpen: false,
      lModalContent: "",
      isLModalOpen: false,
      yesNoOptions,
      includeOptions,
      minValOptions,
      ndcPriceModalOpen: false,
      drugData: {
        gpi: "",
        msc: "",
        commonDrugName: "",
      },
      isNoValidNDCsOpen: false,
      nvnModalHeader: "",
      nvnModalContent: "",
    };
  }

  toggleLoadingModal = () => {
    const lModalContent = (
      <span>
        Clinical Pairing in progress for&nbsp;
        <strong>{this.props.selectedGrid.drugCategory.description}</strong>. You
        will be redirected to the Clinical Pairings Workbook when pairing is
        complete. Please do not navigate away from, or refresh this page, unless
        you receive an error message during the pairing process.
      </span>
    );

    this.setState({ isLModalOpen: !this.state.isLModalOpen, lModalContent });
  };

  toggleNoValidNDCsModal = (dpo) => {
    if (dpo && dpo.hasOwnProperty("medicationName")) {
      const name = dpo.medicationName.commonName;
      const gpi = dpo.gpi;
      const msc = dpo.multiSource;
      const content = (
        <span>
          {name}: <strong>GPI:</strong> {gpi} <strong>MSC:</strong> {msc} does
          not have any valid AR NDCs available and cannot have an Include value
          of Yes - NDC.
        </span>
      );

      this.setState({
        isNoValidNDCsOpen: !this.state.isNoValidNDCsOpen,
        nvnModalContent: content,
        nvnModalHeader: "No Valid AR NDCs",
        nvnModalAction: () => history.go(0),
      });
    }
  };

  toggleExistingWorkbook = () => {
    const eModalContent = (
      <span>
        A clinical pairing workbook already exists for P&amp;T Decision
        Grid:&nbsp;
        <strong>{this.props.selectedGrid.drugCategory.description}</strong>.
        Continuing will replace the existing clinical pairing workbook with a
        new clinical pairing workbook. Do you wish to proceed?
      </span>
    );
    this.setState({
      isExistingWBOpen: !this.state.isExistingWBOpen,
      eModalContent,
      eModalAction: this.pair,
    });
  };

  toggleDeleteModal = (dpo) => {
    if (dpo && dpo.hasOwnProperty("medicationName")) {
      const name = dpo.medicationName.commonName;
      this.setState({
        isDeleteOpen: !this.state.isDeleteOpen,
        dModalHeader: `Delete ${name}`,
        dModalContent: `Are you sure you want to delete ${name}?`,
        dModalAction: this.props.deleteDPO,
        selectedGridId: dpo.id,
      });
    } else {
      this.setState({ isDeleteOpen: !this.state.isDeleteOpen });
    }
  };

  toggleDeleteAllNDCDPOsModal = (dpo) => {
    if (dpo && dpo.hasOwnProperty("medicationName")) {
      const name = dpo.medicationName.commonName;
      this.setState({
        isDeleteAllNDCDPOsOpen: !this.state.isDeleteAllNDCDPOsOpen,
        daModalHeader: "Delete All NDC Drug Pairing Options",
        daModalContent: `Changing the include value to anything other than 'Yes - NDC' will delete all NDC Drug Pairing Options associated with ${name}. Are you sure you wish to continue?`,
        daModalAction: this.deleteAllNDCDPOs,
        selectedDpoId: dpo.id,
      });
    } else {
      this.setState({
        isDeleteAllNDCDPOsOpen: !this.state.isDeleteAllNDCDPOsOpen,
      });
    }
  };

  deleteAllNDCDPOs = (dpoId) => {
    const dpo = _.find(this.props.dpos.content, { id: dpoId });
    if (dpo.included === "N" && _.isEmpty(dpo.notes)) {
      this.toggleNoteModal(dpo);
    } else {
      this.tryUpdateDpo(dpo, true);
    }
  };

  toggleCopyModal = (dpo) => {
    if (dpo && dpo.hasOwnProperty("medicationName")) {
      const name = dpo.medicationName.commonName;
      const gpi = dpo.gpi;
      const msc = dpo.multiSource;
      const content = (
        <>
          <p>
            Are you sure you wish to copy&nbsp;
            <span className="halfBold">{name}</span> with&nbsp;
            <span className="halfBold">GPI: {gpi}</span> and&nbsp;
            <span className="halfBold">MSC: {msc}</span>?
          </p>
          <p>
            All values except{" "}
            <span className="halfBold">PDC, T/A/B, and Notes</span> will be
            duplicated.
          </p>
        </>
      );
      this.setState({
        isCopyOpen: !this.state.isCopyOpen,
        cModalHeader: `Copy Decision Grid Row: ${name}`,
        cModalContent: content,
        cModalAction: this.props.copyDPO,
        cModalCopyId: dpo.id,
      });
    } else {
      this.setState({ isCopyOpen: !this.state.isCopyOpen });
    }
  };

  toggleNoteModal = (dpo) => {
    let noteModalHeader = null;
    let noteModalCurrentNote = null;
    if (dpo) {
      noteModalHeader = `Notes for ${dpo.medicationName.commonName}`;
      noteModalCurrentNote = dpo.notes;
    }
    this.setState({
      isNoteModalOpen: !this.state.isNoteModalOpen,
      noteModalDpo: dpo ? dpo : null,
      noteModalHeader,
      noteModalCurrentNote,
    });
  };

  componentDidMount = () => {
    this.setupSidebar();
    this.props.getDecisionGrid(this.state.dgId);
    this.getPage();
  };

  componentDidUpdate = () => {
    if (!this.state.breadcrumbsLoaded && !_.isEmpty(this.props.selectedGrid)) {
      this.setupBreadCrumbs();
      this.setState({ breadcrumbsLoaded: true });
    }

    if (!_.isEmpty(this.props.selectedGrid) && !this.state.existenceChecked) {
      const gridStatus = this.props.selectedGrid.status.name;
      if (
        gridStatus === VALID_EDIT_STATUSSES.Active ||
        gridStatus === VALID_EDIT_STATUSSES.Draft
      ) {
        this.props.existenceCheck(this.props.selectedGrid.id, gridStatus);
      }

      this.setState({ existenceChecked: true });
    }
  };

  componentWillUnmount = () => {
    this.props.clearDPOS();
  };

  setupSidebar = () => {
    this.props.populateSidebar(getClinicalSidebarItems(this.props.roles));
  };

  setupBreadCrumbs = () => {
    const breadCrumbs = {
      crumbs: [
        { to: "/dashboard", name: "Home", active: false },
        { to: "/grids", name: "P&T Decision Grids", active: false },
      ],
      finalCrumb: this.props.selectedGrid.drugCategory.description,
    };
    this.props.populateBreadCrumbs(breadCrumbs);
  };

  getPage = () => {
    this.props.searchDrugPairingOptions(
      this.state.dgId,
      this.state.searchTerms,
      this.state.currPage - 1,
      this.state.sizePerPage,
      this.state.sortField,
    );
  };

  onSearchSubmit = (searchTerms) => {
    this.setState({ currPage: 1, searchTerms }, () => {
      this.getPage();
    });
  };

  onSizePerPageChange = (sizePerPage, page) => {
    this.setState({ currPage: page, sizePerPage }, () => {
      this.getPage();
    });
  };

  handleTableChange = (
    type,
    { page, sizePerPage, sortField, sortOrder, cellEdit },
  ) => {
    if (type === "sort") {
      sortField = {
        dataField: sortField,
        order: sortOrder,
      };
      this.setState({ sortField }, () => {
        this.getPage();
      });
      return -1;
    }

    if (type === "cellEdit") {
      this.onUpdateFromTableChange(
        cellEdit.rowId,
        cellEdit.dataField,
        cellEdit.newValue,
      );
      return -1;
    }

    this.setState({ currPage: page, sizePerPage: sizePerPage }, () => {
      this.getPage();
    });
  };

  drugNameFormatter = (content, row) => {
    const drugName = row.medicationName.commonName;
    return (
      <Button
        className="altButton"
        color="link"
        onClick={() => this.handleDrugNameOnClick(row)}
      >
        {drugName}
      </Button>
    );
  };

  getNdcPrices = (targetGpi, targetMsc) => {
    this.props.getNDCPricesAction(targetGpi, targetMsc);
  };

  handleDrugNameOnClick = (row) => {
    const drugData = {
      gpi: row.gpi,
      msc: row.multiSource,
      commonDrugName: row.medicationName.commonName,
    };
    this.setState({
      drugData: drugData,
    });

    this.getNdcPrices(row.gpi, row.multiSource);
    this.toggleNdcPriceModal();
  };

  toggleNdcPriceModal = () => {
    if (this.state.ndcPriceModalOpen) {
      this.props.clearNdcPrices();
    }
    this.setState({
      ndcPriceModalOpen: !this.state.ndcPriceModalOpen,
    });
  };

  actionFormatter = (_1, row) => {
    const hasNotes = row.notes && _.trim(row.notes).length > 0;
    const lockedBtn = (
      <ActionButton
        actionType="locked"
        tooltip="You do not have permission to edit this row."
        disabled
      />
    );

    const clinicalApproverOnly = this.getClinicalApproverOnly();

    return (
      <div>
        {clinicalApproverOnly && lockedBtn}
        {!clinicalApproverOnly && (
          <>
            <ActionButton
              actionType="note"
              actionColor={hasNotes ? "green" : "orange"}
              tooltip={hasNotes ? "Edit note" : "Create note"}
              onClick={() => this.toggleNoteModal(row)}
            />
            {!this.state.isDemoUser && (
              <>
                <ActionButton
                  onClick={() => this.toggleDeleteModal(row)}
                  actionType="delete"
                  actionColor="red"
                  tooltip="Delete row"
                />
                <ActionButton
                  onClick={() => this.toggleCopyModal(row)}
                  actionType="copy"
                  actionColor="blue"
                  tooltip="Copy row"
                />
              </>
            )}
            {this.state.isDemoUser && lockedBtn}
          </>
        )}
      </div>
    );
  };

  dropdownRenderer = (
    editorProps,
    value,
    row,
    column,
    rowIndex,
    columnIndex,
  ) => {
    const dataField = column.dataField;
    let selectedValue = "";
    let options = [];

    switch (dataField) {
      case "type":
        selectedValue = row.type;
        options = this.state.tabOptions;
        if (row.included.toLowerCase() === "y") {
          options = _.filter(options, (option) => {
            return option.label !== "NA";
          });
        }
        break;
      case "adminType":
        selectedValue = row.adminType;
        options = this.state.adminTypeOptions;
        if (row.included.toLowerCase() === "y") {
          options = _.filter(options, (option) => {
            return option.label !== "Blank";
          });
        }
        break;
      case "minimumValue":
        selectedValue = row.minimumValue;
        options = this.state.minValOptions;
        break;
      case "included":
        selectedValue = row[dataField] ? row[dataField] : undefined;
        options = this.state.includeOptions;
        break;
      case "divisible":
      case "daconBracket":
        selectedValue = row[dataField] ? row[dataField] : undefined;
        options = this.state.yesNoOptions;
        break;
      default:
        break;
    }

    return (
      <Input
        type="select"
        id={dataField}
        name={dataField}
        value={selectedValue}
        onChange={this.onDropdownChange}
        data-dpoid={row.id}
      >
        <option>--Select--</option>
        {options.map((option) => {
          // For includes, Yes - NDC cannot be selected until all RED required fields have been edited
          let disabled = false;
          let title = null;
          if (dataField === "included" && option.value === "YNDC") {
            disabled = this.setDisabled(row);
            if (disabled) {
              title =
                "Cannot select Yes - NDC until all required edits are made.";
            }
          }

          return (
            <option
              key={option.value}
              value={option.value}
              disabled={disabled}
              title={title}
            >
              {option.label}&nbsp;&nbsp;&nbsp;
            </option>
          );
        })}
      </Input>
    );
  };

  setDisabled = (row) => {
    const emptyCheck = _.overSome([isNull]);
    const propsToCheck = [
      "benchmarkDosage",
      "pairingCode",
      "type",
      "divisible",
      "coursePackageSize",
      "dosageUnitCount",
      "dailyDoseCount",
      "courseDoseDayCount",
      "courseDayCount",
      "daconBracket",
      "adminType",
    ];

    const checkedProps = _.map(propsToCheck, (prop) => {
      return { isEmpty: emptyCheck(row[prop]) };
    });

    return _.some(checkedProps, ["isEmpty", true]);
  };

  getDPOForEdit = (currentTarget) => {
    const dpoId = parseInt(currentTarget.getAttribute("data-dpoid"));
    return _.find(this.props.dpos.content, { id: dpoId });
  };

  onUpdateFromTableChange = (dpoId, field, value) => {
    const dpo = _.find(this.props.dpos.content, { id: dpoId });
    switch (field) {
      case "dailyDoseCount":
      case "courseDoseDayCount":
      case "courseDayCount":
        dpo[field] = parseInt(value);
        break;
      case "benchmarkDosage":
      case "dosageUnitCount":
      case "coursePackageSize":
        dpo[field] = parseFloat(value);
        break;
      default:
        dpo[field] = value;
    }
    this.tryUpdateDpo(dpo);
  };

  onDropdownChange = ({ currentTarget }) => {
    const targetId = currentTarget.id;
    let options = [];
    switch (targetId) {
      case "type":
        options = this.state.tabOptions;
        break;
      case "adminType":
        options = this.state.adminTypeOptions;
        break;
      case "minimumValue":
        options = this.state.minValOptions;
        break;
      case "included":
        options = this.state.includeOptions;
        break;
      case "divisible":
      case "daconBracket":
        options = this.state.yesNoOptions;
        break;
      default:
        break;
    }

    // Make sure user can't change dropdown to an invalid value (Like --Select--)
    if (
      !_.some(options, {
        value: currentTarget.value,
      })
    ) {
      return;
    }
    const dpo = this.getDPOForEdit(currentTarget);
    const prevIncludeVal = dpo.included;

    dpo[currentTarget.id] = currentTarget.value;

    if (targetId === "included" && currentTarget.value === "YNDC") {
      this.props.hasValidNDCs(dpo.id).then((hasValidNDCs) => {
        if (hasValidNDCs) {
          this.props.saveNDCDPO(dpo.id);
          this.tryUpdateDpo(dpo, true);
        } else {
          this.toggleNoValidNDCsModal(dpo);
        }
      });
    } else if (
      targetId === "included" &&
      prevIncludeVal === "YNDC" &&
      currentTarget.value !== prevIncludeVal
    ) {
      // We switched from YNDC to some other value. Alert user that all NDC DPOs will be deleted
      this.toggleDeleteAllNDCDPOsModal(dpo);
    } else if (
      targetId === "included" &&
      currentTarget.value === "N" &&
      _.isEmpty(dpo.notes)
    ) {
      this.toggleNoteModal(dpo);
    } else {
      this.tryUpdateDpo(dpo);
    }
  };

  onNoteSubmit = (formValues) => {
    const dpo = this.state.noteModalDpo;
    dpo.notes = formValues.notes;
    this.tryUpdateDpo(dpo, true);
  };

  onNoteChange = (e) => {
    const target = e.currentTarget;
    const currentVal = target.value;
    this.setState({ noteModalCurrentNote: currentVal });
  };

  tryUpdateDpo = (dpo, doPageRefresh) => {
    this.props.updateDPO(
      dpo.id,
      _.pick(dpo, [
        "included",
        "strength",
        "benchmarkDosage",
        "dosageUnitCount",
        "dailyDoseCount",
        "pairingCode",
        "type",
        "adminType",
        "courseDoseDayCount",
        "courseDayCount",
        "coursePackageSize",
        "divisible",
        "daconBracket",
        "notes",
        "minimumValue",
      ]),
      doPageRefresh,
    );
  };

  tryPairings = () => {
    if (this.props.existingWorkbook) {
      this.toggleExistingWorkbook();
    } else {
      this.pair(this.state.dgId);
    }
  };

  pair = async (wbId) => {
    this.toggleLoadingModal();
    const result = await this.props.tryPairWorkbook(wbId);
    if (result && result.error) {
      this.toggleLoadingModal();
    }
  };

  /**
   * DPO can only be edited if status is Draft or Active and User is Clinician or Clinical Approver.
   * Active Grids can only be edited by Clinical Approver.
   * @param {boolean} editable
   * @param {boolean} clinicalApproverOnly
   * @returns {Object}
   */
  getCellEditFactory = (editable, clinicalApproverOnly) => {
    if (
      !editable ||
      clinicalApproverOnly ||
      !this.state.isAuthorized ||
      this.state.isDemoUser
    ) {
      return {};
    }

    return cellEditFactory({
      mode: "click",
      blurToSave: true,
    });
  };

  fullYesNoIncludeFormatter = (content) => {
    if (!content) {
      return;
    } else {
      return _.find(this.state.includeOptions, { value: content }).label;
    }
  };

  isCellEditable = (cell, row) => {
    return row.included !== "YNDC";
  };

  getColumns = (editable) => {
    const isDemoUser = this.state.isDemoUser;
    const shouldShowMinVal =
      this.props.selectedGrid.drugCategory.minimumValue === "PER_GPI";

    const columns = [
      {
        dataField: "included",
        text: getDemoColumnHeader(
          OBFUSCATION_COLUMNS.INCLUDE,
          "Include?",
          isDemoUser,
        ),
        headerStyle: {
          ...HEADER_STYLES.optionalEdit,
          width: "5%",
        },
        headerAlign: "center",
        align: "center",
        formatter: isDemoUser
          ? obfuscationFormatter
          : this.fullYesNoIncludeFormatter,
        formatExtraData: OBFUSCATION_COLUMNS.INCLUDE,
        editorRenderer: this.dropdownRenderer,
        sort: true,
        headerAttrs: {
          title: COLUMN_LEGEND.OPTIONAL,
        },
      },
      {
        dataField: "gpi",
        sort: true,
        text: "GPI",
        editable: false,
        headerAttrs: {
          title: COLUMN_LEGEND.MEDISPAN,
        },
      },
      {
        dataField: "multiSource",
        text: "MSC",
        editable: false,
        sort: true,
        headerAttrs: {
          title: COLUMN_LEGEND.MEDISPAN,
        },
      },
      {
        dataField: "medicationName.commonName",
        text: "Drug Name",
        editable: false,
        sort: true,
        headerAttrs: {
          title: COLUMN_LEGEND.MEDISPAN,
        },
        formatter: this.drugNameFormatter,
      },
      {
        dataField: "strength",
        text: getDemoColumnHeader(
          OBFUSCATION_COLUMNS.STRENGTH,
          "Strength per Unit",
          isDemoUser,
        ),
        headerStyle: HEADER_STYLES.optionalEdit,
        validator: floatMinGreater0Max99999MaxDec3Req,
        formatter: isDemoUser ? obfuscationFormatter : null,
        style: (cell, row, rowIndex, colIndex) => {
          if (isNaN(_.toNumber(cell))) {
            return {
              color: "red",
              fontWeight: "bold",
            };
          }
        },
        formatExtraData: OBFUSCATION_COLUMNS.STRENGTH,
        headerAttrs: {
          title: COLUMN_LEGEND.OPTIONAL,
        },
        editable: this.isCellEditable,
      },
      {
        dataField: "benchmarkDosage",
        text: getDemoColumnHeader(
          OBFUSCATION_COLUMNS.BENCHMARK,
          "Benchmark Dosage Strength",
          isDemoUser,
        ),
        headerStyle: HEADER_STYLES.requiredEdit,
        validator: floatMinGreater0Max99999MaxDec3Req,
        formatter: isDemoUser ? obfuscationFormatter : null,
        formatExtraData: OBFUSCATION_COLUMNS.BENCHMARK,
        headerAttrs: {
          title: COLUMN_LEGEND.REQUIRED,
        },
        editable: this.isCellEditable,
      },
      {
        dataField: "pairingCode",
        text: getDemoColumnHeader(OBFUSCATION_COLUMNS.PDC, "PDC", isDemoUser),
        sort: true,
        headerStyle: HEADER_STYLES.requiredEdit,
        formatter: isDemoUser ? obfuscationFormatter : null,
        formatExtraData: OBFUSCATION_COLUMNS.PDC,
        headerAttrs: {
          title: COLUMN_LEGEND.REQUIRED,
        },
      },
      {
        dataField: "type",
        text: getDemoColumnHeader(OBFUSCATION_COLUMNS.TAB, "T/A/B", isDemoUser),
        sort: true,
        headerStyle: HEADER_STYLES.requiredEdit,
        editorRenderer: this.dropdownRenderer,
        formatter: isDemoUser ? obfuscationFormatter : null,
        formatExtraData: OBFUSCATION_COLUMNS.TAB,
        headerAttrs: {
          title: COLUMN_LEGEND.REQUIRED,
        },
      },
      {
        dataField: "divisible",
        text: getDemoColumnHeader(
          OBFUSCATION_COLUMNS.DIVISIBLE,
          "Divisible",
          isDemoUser,
        ),
        formatter: isDemoUser ? obfuscationFormatter : fullYesNoFormatter,
        formatExtraData: OBFUSCATION_COLUMNS.DIVISIBLE,
        headerStyle: { ...HEADER_STYLES.requiredEdit, width: "5%" },
        editorRenderer: this.dropdownRenderer,
        headerAttrs: {
          title: COLUMN_LEGEND.REQUIRED,
        },
        editable: this.isCellEditable,
      },
      {
        dataField: "coursePackageSize",
        text: getDemoColumnHeader(
          OBFUSCATION_COLUMNS.PACKAGE_SIZE_PER_COURSE,
          "Pack Size per Course",
          isDemoUser,
        ),
        headerStyle: HEADER_STYLES.requiredEdit,
        validator: floatMin1Max99999MaxDec3Req,
        formatter: isDemoUser ? obfuscationFormatter : null,
        formatExtraData: OBFUSCATION_COLUMNS.PACKAGE_SIZE_PER_COURSE,
        headerAttrs: {
          title: COLUMN_LEGEND.REQUIRED,
        },
        editable: this.isCellEditable,
      },
      {
        dataField: "dosageUnitCount",
        text: getDemoColumnHeader(
          OBFUSCATION_COLUMNS.UNIT_PER_1_DOSE,
          "Units per 1 Dose",
          isDemoUser,
        ),
        headerStyle: HEADER_STYLES.requiredEdit,
        validator: floatMinGreater0Max99999MaxDec3Req,
        formatter: isDemoUser ? obfuscationFormatter : null,
        formatExtraData: OBFUSCATION_COLUMNS.UNIT_PER_1_DOSE,
        headerAttrs: {
          title: COLUMN_LEGEND.REQUIRED,
        },
        editable: this.isCellEditable,
      },
      {
        dataField: "dailyDoseCount",
        text: getDemoColumnHeader(
          OBFUSCATION_COLUMNS.DOSES_PER_DAY,
          "Doses per Day",
          isDemoUser,
        ),
        headerStyle: HEADER_STYLES.requiredEdit,
        validator: intMin1Max99Req,
        formatter: isDemoUser ? obfuscationFormatter : null,
        formatExtraData: OBFUSCATION_COLUMNS.DOSES_PER_DAY,
        headerAttrs: {
          title: COLUMN_LEGEND.REQUIRED,
        },
        editable: this.isCellEditable,
      },
      {
        dataField: "courseDoseDayCount",
        text: getDemoColumnHeader(
          OBFUSCATION_COLUMNS.ADMIN_DAYS_PER_COURSE,
          "Admin Days per Course",
          isDemoUser,
        ),
        headerStyle: HEADER_STYLES.requiredEdit,
        validator: intMin1Max99Req,
        formatter: isDemoUser ? obfuscationFormatter : null,
        formatExtraData: OBFUSCATION_COLUMNS.ADMIN_DAYS_PER_COURSE,
        headerAttrs: {
          title: COLUMN_LEGEND.REQUIRED,
        },
        editable: this.isCellEditable,
      },
      {
        dataField: "courseDayCount",
        text: getDemoColumnHeader(
          OBFUSCATION_COLUMNS.DAYS_PER_COURSE,
          "Days per Course",
          isDemoUser,
        ),
        headerStyle: HEADER_STYLES.requiredEdit,
        validator: intMin1Max999Req,
        formatter: isDemoUser ? obfuscationFormatter : null,
        formatExtraData: OBFUSCATION_COLUMNS.DAYS_PER_COURSE,
        headerAttrs: {
          title: COLUMN_LEGEND.REQUIRED,
        },
        editable: this.isCellEditable,
      },
      {
        dataField: "totalDailyDose",
        text: getDemoColumnHeader(
          OBFUSCATION_COLUMNS.TOTAL_DAILY_DOSE,
          "Total Daily Dose",
          isDemoUser,
        ),
        editable: false,
        headerStyle: HEADER_STYLES.calculated,
        formatter: isDemoUser ? obfuscationFormatter : null,
        formatExtraData: OBFUSCATION_COLUMNS.TOTAL_DAILY_DOSE,
        headerAttrs: {
          title: COLUMN_LEGEND.CALCULATED,
        },
      },
      {
        dataField: "doseStrength",
        text: getDemoColumnHeader(
          OBFUSCATION_COLUMNS.STRENGTH_PER_DOSE,
          "Strength per Dose",
          isDemoUser,
        ),
        editable: false,
        headerStyle: HEADER_STYLES.calculated,
        formatter: isDemoUser ? obfuscationFormatter : null,
        formatExtraData: OBFUSCATION_COLUMNS.STRENGTH_PER_DOSE,
        headerAttrs: {
          title: COLUMN_LEGEND.CALCULATED,
        },
      },
      {
        dataField: "standardDacon",
        text: "Standard DACon",
        editable: false,
        sort: true,
        headerStyle: HEADER_STYLES.calculated,
        headerAttrs: {
          title: COLUMN_LEGEND.CALCULATED,
        },
      },
      {
        dataField: "doseEquivalency",
        text: getDemoColumnHeader(
          OBFUSCATION_COLUMNS.DOSE_EQ,
          "Dose Equivalency",
          isDemoUser,
        ),
        editable: false,
        sort: true,
        headerStyle: HEADER_STYLES.calculated,
        formatter: isDemoUser ? obfuscationFormatter : null,
        formatExtraData: OBFUSCATION_COLUMNS.DOSE_EQ,
        headerAttrs: {
          title: COLUMN_LEGEND.CALCULATED,
        },
      },
      {
        dataField: "daconBracket",
        text: getDemoColumnHeader(
          OBFUSCATION_COLUMNS.DACON_BRACKET,
          "DACon Bracket",
          isDemoUser,
        ),
        formatter: isDemoUser ? obfuscationFormatter : fullYesNoFormatter,
        formatExtraData: OBFUSCATION_COLUMNS.DACON_BRACKET,
        headerStyle: { ...HEADER_STYLES.requiredEdit, width: "5%" },
        editorRenderer: this.dropdownRenderer,
        headerAttrs: {
          title: COLUMN_LEGEND.REQUIRED,
        },
        editable: this.isCellEditable,
      },
      {
        dataField: "uom",
        text: getDemoColumnHeader(OBFUSCATION_COLUMNS.UOM, "UOM", isDemoUser),
        editable: false,
        sort: true,
        formatter: isDemoUser ? obfuscationFormatter : null,
        formatExtraData: OBFUSCATION_COLUMNS.UOM,
        headerAttrs: {
          title: COLUMN_LEGEND.MEDISPAN,
        },
      },
      {
        dataField: "dosageForm",
        text: getDemoColumnHeader(
          OBFUSCATION_COLUMNS.DOSAGE_FORM,
          "Dosage Form",
          isDemoUser,
        ),
        editable: false,
        formatter: isDemoUser ? obfuscationFormatter : null,
        formatExtraData: OBFUSCATION_COLUMNS.DOSAGE_FORM,
        headerAttrs: {
          title: COLUMN_LEGEND.MEDISPAN,
        },
      },
      {
        dataField: "routeOfAdmin",
        text: getDemoColumnHeader(
          OBFUSCATION_COLUMNS.ROUTE_OF_ADMIN,
          "Route Admin",
          isDemoUser,
        ),
        editable: false,
        sort: true,
        formatter: isDemoUser ? obfuscationFormatter : null,
        formatExtraData: OBFUSCATION_COLUMNS.ROUTE_OF_ADMIN,
        headerAttrs: {
          title: COLUMN_LEGEND.MEDISPAN,
        },
      },
      {
        dataField: "adminType",
        text: "Provider or Self Administered",
        sort: true,
        headerStyle: HEADER_STYLES.requiredEdit,
        editorRenderer: this.dropdownRenderer,
        headerAttrs: {
          title: COLUMN_LEGEND.REQUIRED,
        },
      },
    ];

    if (shouldShowMinVal) {
      columns.splice(5, 0, {
        dataField: "minimumValue",
        text: "Minimum GPI(12) Fill Factor Value",
        sort: true,
        headerStyle: HEADER_STYLES.requiredEdit,
        formatter: minimumFillFactorValueFormatter,
        editorRenderer: this.dropdownRenderer,
        headerAttrs: {
          title: `${COLUMN_LEGEND.REQUIRED} \n\n${MIN_ONE_TIP} \n\n${NO_MIN_TIP}`,
        },
      });
    }

    if (
      editable &&
      this.state.isAuthorized &&
      !_.some(columns, { dataField: "actions" })
    ) {
      columns.unshift({
        dataField: "actions",
        text: "Action",
        formatter: this.actionFormatter,
        isDummyField: true,
        headerStyle: {
          width: "4%",
        },
        editable: false,
      });
    }
    return columns;
  };

  getClinicalApproverOnly = () => {
    return (
      this.props.selectedGrid.status.name === VALID_EDIT_STATUSSES.Active &&
      !authCheck(this.props.roles, Role.CLINICAL_APPROVER.name)
    );
  };

  getNDCExpandRow = (editable) => {
    const rows = this.props.dpos.content;
    const nonExpandable = _.map(
      _.filter(rows, (row) => {
        return row.included === "Y" || row.included === "N";
      }),
      "id",
    );

    return {
      renderer: (row, rowIndex) => {
        return (
          <NDCExpandRow
            dpo={row}
            editable={editable}
            isDemoUser={this.state.isDemoUser}
            isAuthorized={this.state.isAuthorized}
            clinicalApproverOnly={this.getClinicalApproverOnly()}
          />
        );
      },
      onlyOneExpanding: true,
      showExpandColumn: true,
      expandByColumnOnly: true,
      nonExpandable,
      expandHeaderColumnRenderer: () => null,
    };
  };

  render = () => {
    const selectedGrid = this.props.selectedGrid;
    if (!selectedGrid || _.isEmpty(this.props.dpos)) {
      return <h1>LOADING...</h1>;
    }
    const editable =
      selectedGrid.status.name === VALID_EDIT_STATUSSES.Draft ||
      selectedGrid.status.name === VALID_EDIT_STATUSSES.Active;

    const noteModalDPOisIncluded =
      this.state.noteModalDpo && this.state.noteModalDpo.included === "Y";

    const btnContainerClass =
      this.state.isAuthorized && editable
        ? "justify-content-between"
        : "flex-row-reverse";

    const pairingToolTip = selectedGrid.lockedDate
      ? "Pairing for this workbook is currently in progress"
      : null;

    return (
      <Container className="main-container" fluid>
        <Row>
          <Col>
            <h3>
              {selectedGrid.drugCategory.description}&nbsp;&nbsp;&nbsp;
              <strong>Status:&nbsp;{selectedGrid.status.name}</strong>
            </h3>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col className="">
            <DPOSearchForm
              onSubmit={this.onSearchSubmit}
              hasNDCDPOs={selectedGrid.hasNDCDPOs}
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col className={`d-flex ${btnContainerClass}`}>
            {this.state.isAuthorized && editable && (
              <div>
                <Button
                  color="primary"
                  tag={Link}
                  to={`/grids/${selectedGrid.id}/add-dpo`}
                  disabled={this.state.isDemoUser}
                >
                  Add GPI
                </Button>
                <div className="pairing-btn-wrapper" title={pairingToolTip}>
                  <Button
                    className="ms-3 pairing-btn"
                    color="primary"
                    onClick={this.tryPairings}
                    disabled={selectedGrid.lockedDate ? true : false}
                    title={pairingToolTip}
                  >
                    Run Clinical Pairings
                  </Button>
                </div>
              </div>
            )}
            <Button
              className=""
              color="primary"
              tag={Link}
              to={`/grids-audit/${selectedGrid.id}`}
            >
              View Audit Log
            </Button>
          </Col>
        </Row>
        <Row className="mt-3 overflow-auto dg-no-expand-all">
          <Col>
            <RPSDataGrid
              keyField="id"
              remote
              paginated
              sizePerPage={this.state.sizePerPage}
              page={this.state.currPage}
              totalSize={this.props.dpos.totalElements}
              data={this.props.dpos.content}
              columns={this.getColumns(editable)}
              onSizePerPageChange={this.onSizePerPageChange}
              onTableChange={this.handleTableChange}
              cellEditFactory={this.getCellEditFactory(
                editable,
                this.getClinicalApproverOnly(),
              )}
              expandRow={this.getNDCExpandRow(editable, isDemoUser)}
            />
          </Col>
        </Row>
        <LoadingModal
          isOpen={this.state.isLModalOpen}
          toggle={this.toggleLoadingModal}
          header="Clinical Pairing in Progress"
          content={this.state.lModalContent}
        />
        <ConfirmationModal
          isOpen={this.state.isDeleteAllNDCDPOsOpen}
          toggle={this.toggleDeleteAllNDCDPOsModal}
          header={this.state.daModalHeader}
          content={this.state.daModalContent}
          action={this.state.daModalAction}
          objId={this.state.selectedDpoId}
          cancelAction={() => {
            history.go(0);
          }}
        />
        <ConfirmationModal
          isOpen={this.state.isNoValidNDCsOpen}
          toggle={this.toggleNoValidNDCsModal}
          header={this.state.nvnModalHeader}
          content={this.state.nvnModalContent}
          action={this.state.nvnModalAction}
          objId={this.state.selectedDpoId}
          confirmText="OK"
          cancelAction={() => {
            history.go(0);
          }}
        />
        <ConfirmationModal
          isOpen={this.state.isDeleteOpen}
          toggle={this.toggleDeleteModal}
          header={this.state.dModalHeader}
          content={this.state.dModalContent}
          action={this.state.dModalAction}
          objId={this.state.selectedGridId}
        />
        <ConfirmationModal
          isOpen={this.state.isExistingWBOpen}
          toggle={this.toggleExistingWorkbook}
          header="Clinical Pairing Workbook Already Exists"
          content={this.state.eModalContent}
          action={this.state.eModalAction}
          objId={this.state.dgId}
        />
        <ConfirmationModal
          isOpen={this.state.isCopyOpen}
          toggle={this.toggleCopyModal}
          header={this.state.cModalHeader}
          content={this.state.cModalContent}
          action={this.state.cModalAction}
          objId={this.state.cModalCopyId}
        />
        <NDCPriceModal
          isOpen={this.state.ndcPriceModalOpen}
          toggle={this.toggleNdcPriceModal}
          ndcPrices={this.props.ndcPrices}
          clearAction={this.props.clearNdcPrices}
          drugData={this.state.drugData}
          showSequence={false}
        />
        <FormModal
          isOpen={this.state.isNoteModalOpen}
          toggle={this.toggleNoteModal}
          header={this.state.noteModalHeader ? this.state.noteModalHeader : ""}
          onSubmit={this.onNoteSubmit}
          usePristine={noteModalDPOisIncluded ? false : true}
          cancelCallback={() => {
            history.go(0);
          }}
        >
          <FormGroup>
            <Field
              id="notes"
              name="notes"
              type="textarea"
              label="Notes"
              numRows={10}
              required={
                this.state.noteModalDpo
                  ? this.state.noteModalDpo.included
                  : false
              }
              inputobj={{
                body: this.state.noteModalCurrentNote
                  ? this.state.noteModalCurrentNote
                  : "",
              }}
              onChange={this.onNoteChange}
              validate={noteModalDPOisIncluded ? [] : [required]}
              component={renderTextArea}
            />
          </FormGroup>
        </FormModal>
      </Container>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    roles: state.auth.currentUser.roles,
    selectedGrid: state.drugPairingOptions.selectedGrid,
    dpos: state.drugPairingOptions.dpos,
    existingWorkbook: state.workbooks.existingWorkbook,
    ndcPrices: Object.values(state.ndcPrices),
  };
};

export default connect(mapStateToProps, {
  populateBreadCrumbs,
  populateSidebar,
  getDecisionGrid,
  searchDrugPairingOptions,
  existenceCheck,
  tryPairWorkbook,
  deleteDPO,
  clearDPOS,
  copyDPO,
  updateDPO,
  getNDCPricesAction,
  clearNdcPrices,
  saveNDCDPO,
  hasValidNDCs,
})(DecisionGridView);
