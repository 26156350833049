import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Col, Container, FormGroup, Row } from "reactstrap";
import { FaCircle, FaLock, FaMoon, FaPencilAlt } from "react-icons/fa";
import { Link } from "react-router-dom";
import {
  trySaveDrugCategory,
  tryUpdateDrugCategory,
  getDrugCategories,
  getCategoryCodes,
  populateBreadCrumbs,
  populateSidebar,
  clearSidebarActions,
} from "../../../actions";
import { authCheck, Role } from "../../../utils/auth/roles";
import _ from "lodash";
import { getClinicalSidebarItems } from "../Constants";
import RPSDataGrid from "../../DataGrid/RPSDataGrid";
import FormModal from "../../Modal/FormModal";
import { Field } from "redux-form";
import {
  booleanYNFormatter,
  dateFormatter,
  minimumFillFactorValueFormatter,
  minLength3,
  renderInput,
  renderSelectInput,
  required,
  validSelect,
} from "../../../utils";
import AlertModal from "../../Modal/AlertModal";
import ConfirmationModal from "../../Modal/ConfirmationModal";
import {
  DC_COL_TOOLTIPS,
  minimumValues,
} from "../../../utils/models/drugCategory";

class DrugCategories extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAddModalOpen: false,
      isAlertModalOpen: false,
      isConfirmModalOpen: false,
      isAuthorized: authCheck(this.props.roles, Role.CLINICAL_APPROVER.name),
      formInitialValues: null,
      retireCategory: null,
      alertContent: "",
      confirmContent: "",
      formModalHeader: "",
    };
  }

  componentDidMount = () => {
    this.setupBreadCrumbs();
    this.setupSidebar();
    this.props.getDrugCategories();
    this.props.getCategoryCodes(true);
    this.props.getCategoryCodes(false);
  };

  componentWillUnmount = () => {
    this.props.clearSidebarActions();
  };

  setupBreadCrumbs = () => {
    const breadCrumbs = {
      crumbs: [{ to: "/dashboard", name: "Home", active: false }],
      finalCrumb: "Drug Categories",
    };
    this.props.populateBreadCrumbs(breadCrumbs);
  };

  setupSidebar = () => {
    this.props.populateSidebar(getClinicalSidebarItems(this.props.roles));
  };

  toggleModal = (_, formModalHeader = "Add Drug Category") => {
    const isAddModalOpen = !this.state.isAddModalOpen;
    if (!isAddModalOpen) {
      this.setState({
        isAddModalOpen,
        formInitialValues: null,
        formModalHeader,
      });
    } else {
      this.setState({ isAddModalOpen, formModalHeader });
    }
  };

  toggleAlertModal = () => {
    const alertContent = (
      <span>
        You cannot retire&nbsp;
        <strong>{this.state.retireCategory.description}</strong> because a
        P&amp;T Decision Grid associated with this drug category has a status
        of&nbsp;
        <strong>Draft</strong>.
      </span>
    );
    this.setState({
      isAlertModalOpen: !this.state.isAlertModalOpen,
      alertContent,
    });
  };

  toggleConfirmModal = () => {
    const confirmContent = (
      <span>
        Retiring <strong>{this.state.retireCategory.description}</strong> will
        also retire the <strong>Active</strong> P&amp;T Decision Grid. Retiring
        this drug category will also&nbsp;
        <strong>PERMANENTLY REMOVE</strong> any clinical pairing workbooks
        (including approved clinical pairing workbooks) associated with this
        drug category. Press Yes to retire.
      </span>
    );
    this.setState({
      isConfirmModalOpen: !this.state.isConfirmModalOpen,
      confirmContent,
    });
  };

  onConfirmClicked = () => {
    const category = this.state.retireCategory;
    category.retired = true;
    this.props.tryUpdateDrugCategory(category);
  };

  onAddSubmit = (formValues) => {
    const data = {
      description: _.trim(formValues.description),
      conditionCode: _.find(this.props.conditionCodes, {
        id: parseInt(formValues.conditionCodeId),
      }),
      clinicalImpactCode: _.find(this.props.clinicalImpactCodes, {
        id: parseInt(formValues.clinicalImpactCodeId),
      }),
      minimumValue: formValues.minimumValue,
    };

    if (formValues.categoryId) {
      data.id = formValues.categoryId;
      this.props.tryUpdateDrugCategory(data);
    } else {
      this.props.trySaveDrugCategory(data);
    }
  };

  actionFormatter = (content, row) => {
    return (
      <>
        {!row.retired && (
          <>
            <Button
              className="linkBtn tableBtn actionIconLinkPrimary"
              color="link"
              title="Retire Drug Category"
              onClick={() => {
                this.setState({ retireCategory: row }, () => {
                  const draftDg = _.find(row.decisionGrids, (grid) => {
                    return grid.status.name === "Draft";
                  });
                  if (draftDg) {
                    this.toggleAlertModal();
                  } else {
                    this.toggleConfirmModal();
                  }
                });
              }}
            >
              <FaMoon />
            </Button>
            <Button
              className="linkBtn tableBtn actionIconLinkPrimary"
              color="link"
              title="Edit Drug Category"
              onClick={() => {
                this.setState(
                  {
                    formInitialValues: {
                      categoryId: row.id,
                      description: row.description,
                      conditionCodeId: row.conditionCode.id,
                      clinicalImpactCodeId: row.clinicalImpactCode.id,
                      minimumValue: row.minimumValue,
                    },
                  },
                  () => {
                    this.toggleModal(null, "Edit Drug Category");
                  },
                );
              }}
            >
              <FaPencilAlt />
            </Button>
          </>
        )}
        {row.retired && (
          <Button className="linkBtn tableBtn" color="link" disabled>
            <FaLock />
          </Button>
        )}
      </>
    );
  };

  getColumns = () => {
    const columns = [
      {
        dataField: "retired",
        sort: true,
        text: "Retired",
        formatter: booleanYNFormatter,
        headerStyle: {
          width: "3%",
        },
        headerAttrs: {
          title: DC_COL_TOOLTIPS.RETIRED,
        },
      },
      {
        dataField: "description",
        sort: true,
        text: "Drug Category",
        headerAttrs: {
          title: DC_COL_TOOLTIPS.DRUG_CATEGORY,
        },
      },
      {
        dataField: "conditionCode.description",
        sort: true,
        text: "Condition Code",
        headerAttrs: {
          title: DC_COL_TOOLTIPS.CONDITION_CODE,
        },
      },
      {
        dataField: "clinicalImpactCode",
        sort: true,
        text: "Clinical Impact",
        formatter: (content, _) => {
          const className =
            content.code === "RED"
              ? "clinicalimpact-red"
              : "clinicalimpact-green";
          return <FaCircle className={className} title={content.description} />;
        },
        headerStyle: {
          width: "8%",
        },
        headerAttrs: {
          title: DC_COL_TOOLTIPS.CLINICAL_IMPACT,
        },
      },
      {
        dataField: "minimumValue",
        sort: true,
        text: "Minimum Fill Factor Value",
        formatter: minimumFillFactorValueFormatter,
        headerStyle: {
          width: "8%",
        },
        headerAttrs: {
          title: DC_COL_TOOLTIPS.MINIMUM_VALUE,
        },
      },
      {
        dataField: "updatedDate",
        sort: true,
        text: "Updated Date",
        formatter: dateFormatter,
        headerStyle: {
          width: "8%",
        },
        headerAttrs: {
          title: DC_COL_TOOLTIPS.UPDATED_DATE,
        },
      },
    ];
    if (this.state.isAuthorized) {
      columns.unshift({
        dataField: "actions",
        sort: false,
        text: "Action",
        formatter: this.actionFormatter,
        isDummyField: true,
        headerStyle: {
          width: "8%",
        },
      });
    }

    return columns;
  };

  render = () => {
    if (
      !this.props.categories ||
      _.isEmpty(this.props.conditionCodes) ||
      _.isEmpty(this.props.clinicalImpactCodes)
    ) {
      return <h1>Loading...</h1>;
    }
    const auditTarget = "/drug-categories/audit/";
    const buttonContainerClass = this.state.isAuthorized
      ? "justify-content-between"
      : "flex-row-reverse";
    return (
      <Container className="main-container" fluid>
        <Row>
          <Col>
            <h3>Drug Categories</h3>
          </Col>
        </Row>
        <Row>
          <Col className={`mt-3 d-flex ${buttonContainerClass}`}>
            {this.state.isAuthorized && (
              <Button
                className="mb-3"
                color="primary"
                onClick={this.toggleModal}
              >
                Add New Drug Category
              </Button>
            )}
            <Button
              className="mb-3"
              color="primary"
              tag={Link}
              to={auditTarget}
            >
              View Audit Log
            </Button>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col>
            <RPSDataGrid
              keyField="id"
              data={this.props.categories}
              columns={this.getColumns()}
            />
          </Col>
        </Row>
        <FormModal
          isOpen={this.state.isAddModalOpen}
          toggle={this.toggleModal}
          header={this.state.formModalHeader}
          onSubmit={this.onAddSubmit}
          initialValues={this.state.formInitialValues}
        >
          <FormGroup>
            <Field
              id="description"
              name="description"
              type="text"
              placeholder="Drug Category Name"
              required
              validate={[required, minLength3]}
              component={renderInput}
            />
          </FormGroup>
          <FormGroup>
            <Field
              id="conditionCodeId"
              name="conditionCodeId"
              type="select"
              label="Condition Code"
              required
              items={this.props.conditionCodes}
              valueProp="id"
              keyProp="id"
              displayProp="description"
              validate={[required, validSelect]}
              component={renderSelectInput}
            />
          </FormGroup>
          <FormGroup>
            <Field
              id="clinicalImpactCodeId"
              name="clinicalImpactCodeId"
              type="select"
              label="Clinical Impact"
              required
              items={this.props.clinicalImpactCodes}
              valueProp="id"
              keyProp="id"
              displayProp="description"
              validate={[required, validSelect]}
              component={renderSelectInput}
            />
          </FormGroup>
          <FormGroup>
            <Field
              id="minimumValue"
              name="minimumValue"
              type="select"
              label="Minimum Fill Factor Value"
              required
              items={minimumValues}
              valueProp="value"
              keyProp="key"
              displayProp="label"
              validate={[required, validSelect]}
              component={renderSelectInput}
            />
          </FormGroup>
          <Field
            id="categoryId"
            name="categoryId"
            type="hidden"
            component={renderInput}
          />
        </FormModal>
        <AlertModal
          isOpen={this.state.isAlertModalOpen}
          toggle={this.toggleAlertModal}
          header="Retire Not Available"
          content={this.state.alertContent}
        />
        <ConfirmationModal
          isOpen={this.state.isConfirmModalOpen}
          toggle={this.toggleConfirmModal}
          header="Retire Drug Category"
          content={this.state.confirmContent}
          action={this.onConfirmClicked}
          objId={0}
        />
      </Container>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    roles: state.auth.currentUser.roles,
    categories: state.drugCategories.categories,
    conditionCodes: state.drugCategories.conditionCodes,
    clinicalImpactCodes: state.drugCategories.clinicalImpactCodes,
  };
};

export default connect(mapStateToProps, {
  trySaveDrugCategory,
  tryUpdateDrugCategory,
  getDrugCategories,
  getCategoryCodes,
  populateBreadCrumbs,
  populateSidebar,
  clearSidebarActions,
})(DrugCategories);
