import React, { Component } from "react";
import { connect } from "react-redux";
import { Col, FormGroup, Input, Row } from "reactstrap";
import RPSDataGrid from "../../DataGrid/RPSDataGrid";
import cellEditFactory from "react-bootstrap-table2-editor";
import { getNDCDPOs, clearDPOS, updateDPO, deleteDPO } from "../../../actions";
import {
  COLUMN_LEGEND,
  HEADER_STYLES,
  OBFUSCATION_COLUMNS,
  fullYesNoFormatter,
  getDemoColumnHeader,
  gpiNDCFormatter,
  obfuscationFormatter,
  renderTextArea,
  required,
  getDashedNDC,
} from "../../../utils";
import {
  floatMin1Max99999MaxDec3Req,
  floatMinGreater0Max99999MaxDec3Req,
  intMin1Max999Req,
  intMin1Max99Req,
} from "./editValidators";
import _ from "lodash";
import ActionButton from "../../DataGrid/ActionButton";
import FormModal from "../../Modal/FormModal";
import { Field } from "redux-form";
import history from "../../../history";
import ConfirmationModal from "../../Modal/ConfirmationModal";

class NDCExpandRow extends Component {
  constructor(props) {
    super(props);

    const yesNoOptions = [
      { label: "Yes", value: "Y" },
      { label: "No", value: "N" },
    ];

    const includeOptions = [
      { label: "Yes", value: "Y" },
      { label: "No", value: "N" },
      { label: "InActive", value: "I" },
      { label: "Not Yet Evaluated", value: "NYE" },
    ];

    this.state = {
      currPage: 1,
      sizePerPage: 15,
      sortField: null,
      dpo: props.dpo,
      yesNoOptions,
      includeOptions,
      isDeleteOpen: false,
      isNoteModalOpen: false,
      noteModalDpo: null,
      noteModalHeader: null,
      noteModalCurrentNote: null,
    };
  }

  componentDidMount = () => {
    this.getPage();
  };

  toggleDeleteModal = (dpo) => {
    if (dpo && dpo.hasOwnProperty("ndcMedicationName")) {
      const childName = dpo.ndcMedicationName;
      const childNDC = getDashedNDC(dpo.ndc);
      const header = `Delete NDC ${childNDC} (${childName})`;

      if (this.state.dpo.ndcDPOs.length === 1) {
        // User is trying to delete the last child of the GPI/MSC DPO.
        const parentName = this.state.dpo.medicationName.commonName;
        const content = (
          <span>
            <strong>{childNDC}</strong> is the last NDC for&nbsp;
            <strong>{parentName}</strong>. If you remove this NDC,&nbsp;
            <strong>{parentName}</strong> will have it's include value reset to
            <strong>&nbsp;Yes - GPI</strong>. Do you wish to continue?
          </span>
        );

        this.setState({
          isDeleteOpen: !this.state.isDeleteOpen,
          dModalHeader: header,
          dModalContent: content,
          dModalAction: this.deleteLastChild,
          selectedGridId: dpo.id,
        });
      } else {
        const content = (
          <span>
            Are you sure you want to delete{" "}
            <strong>
              {childNDC} ({childName})
            </strong>
            ?
          </span>
        );
        this.setState({
          isDeleteOpen: !this.state.isDeleteOpen,
          dModalHeader: header,
          dModalContent: content,
          dModalAction: this.props.deleteDPO,
          selectedGridId: dpo.id,
        });
      }
    } else {
      this.setState({ isDeleteOpen: !this.state.isDeleteOpen });
    }
  };

  deleteLastChild = (dpoId) => {
    const parent = this.state.dpo;
    parent.included = "Y";
    this.tryUpdateDpo(parent, false, false);

    this.props.deleteDPO(dpoId);
  };

  toggleNoteModal = (dpo) => {
    let noteModalHeader = null;
    let noteModalCurrentNote = null;
    if (dpo) {
      noteModalHeader = `Notes for ${dpo.medicationName}`;
      noteModalCurrentNote = dpo.notes;
    }
    this.setState({
      isNoteModalOpen: !this.state.isNoteModalOpen,
      noteModalDpo: dpo ? dpo : null,
      noteModalHeader,
      noteModalCurrentNote,
    });
  };

  onNoteSubmit = (formValues) => {
    const dpo = this.state.noteModalDpo;
    dpo.notes = formValues.notes;
    this.tryUpdateDpo(dpo, true);
  };

  onNoteChange = (e) => {
    const target = e.currentTarget;
    const currentVal = target.value;
    this.setState({ noteModalCurrentNote: currentVal });
  };

  getPage() {
    this.props.getNDCDPOs(
      this.state.dpo.id,
      this.state.currPage - 1,
      this.state.sizePerPage,
      this.state.sortField,
    );
  }

  dropdownRenderer = (
    editorProps,
    value,
    row,
    column,
    rowIndex,
    columnIndex,
  ) => {
    const dataField = column.dataField;
    let selectedValue = "";
    let options = [];

    switch (dataField) {
      case "included":
        selectedValue = row[dataField] ? row[dataField] : undefined;
        options = this.state.includeOptions;
        break;
      case "divisible":
      case "daconBracket":
        selectedValue = row[dataField] ? row[dataField] : undefined;
        options = this.state.yesNoOptions;
        break;
      default:
        break;
    }

    return (
      <Input
        type="select"
        id={dataField}
        name={dataField}
        value={selectedValue}
        onChange={this.onDropdownChange}
        data-dpoid={row.id}
      >
        <option>--Select--</option>
        {options.map((option) => {
          return (
            <option key={option.value} value={option.value}>
              {option.label}&nbsp;&nbsp;&nbsp;
            </option>
          );
        })}
      </Input>
    );
  };

  onDropdownChange = ({ currentTarget }) => {
    const targetId = currentTarget.id;
    let options = [];
    switch (targetId) {
      case "included":
        options = this.state.includeOptions;
        break;
      case "divisible":
      case "daconBracket":
        options = this.state.yesNoOptions;
        break;
      default:
        break;
    }

    // Make sure user can't change dropdown to an invalid value (Like --Select--)
    if (
      !_.some(options, {
        value: currentTarget.value,
      })
    ) {
      return;
    }
    const dpo = this.getDPOForEdit(currentTarget);
    dpo[currentTarget.id] = currentTarget.value;
    if (
      targetId === "included" &&
      currentTarget.value !== "Y" &&
      _.isEmpty(dpo.notes)
    ) {
      this.toggleNoteModal(dpo);
    } else {
      this.tryUpdateDpo(dpo);
    }
  };

  getDPOForEdit = (currentTarget) => {
    const dpoId = parseInt(currentTarget.getAttribute("data-dpoid"));
    return _.find(this.props.ndcDPOs.content, { id: dpoId });
  };

  tryUpdateDpo = (dpo, doPageRefresh, isNDC = true) => {
    this.props.updateDPO(
      dpo.id,
      _.pick(dpo, [
        "included",
        "strength",
        "benchmarkDosage",
        "dosageUnitCount",
        "dailyDoseCount",
        "courseDoseDayCount",
        "courseDayCount",
        "coursePackageSize",
        "divisible",
        "daconBracket",
        "notes",
        "minimumValue",
        "pairingCode",
        "type",
        "adminType",
      ]),
      doPageRefresh,
      isNDC,
    );
  };

  onSizePerPageChange = (sizePerPage, page) => {
    this.setState({ currPage: page, sizePerPage }, () => {
      this.getPage();
    });
  };

  handleTableChange = (
    type,
    { page, sizePerPage, sortField, sortOrder, cellEdit },
  ) => {
    if (type === "sort") {
      sortField = {
        dataField: sortField,
        order: sortOrder,
      };
      this.setState({ sortField }, () => {
        this.getPage();
      });
      return -1;
    }

    if (type === "cellEdit") {
      this.onUpdateFromTableChange(
        cellEdit.rowId,
        cellEdit.dataField,
        cellEdit.newValue,
      );
      return -1;
    }

    this.setState({ currPage: page, sizePerPage: sizePerPage }, () => {
      this.getPage();
    });
  };

  onUpdateFromTableChange = (dpoId, field, value) => {
    const dpo = _.find(this.props.ndcDPOs.content, { id: dpoId });
    switch (field) {
      case "dailyDoseCount":
      case "courseDoseDayCount":
      case "courseDayCount":
        dpo[field] = parseInt(value);
        break;
      case "benchmarkDosage":
      case "dosageUnitCount":
      case "coursePackageSize":
        dpo[field] = parseFloat(value);
        break;
      default:
        dpo[field] = value;
    }
    this.tryUpdateDpo(dpo);
  };

  /**
   * DPO can only be edited if status is Draft or Active and User is Clinician or Clinical Approver.
   * Active Grids can only be edited by Clinical Approver.
   * @param {boolean} editable
   * @param {boolean} clinicalApproverOnly
   * @returns {Object}
   */
  getCellEditFactory = () => {
    if (
      !this.props.editable ||
      this.props.clinicalApproverOnly ||
      !this.props.isAuthorized ||
      this.props.isDemoUser
    ) {
      return {};
    }

    return cellEditFactory({
      mode: "click",
      blurToSave: true,
    });
  };

  fullYesNoIncludeFormatter = (content) => {
    if (!content) {
      return;
    } else {
      return _.find(this.state.includeOptions, { value: content }).label;
    }
  };

  actionFormatter = (_1, row) => {
    const hasNotes = row.notes && _.trim(row.notes).length > 0;
    const lockedBtn = (
      <ActionButton
        actionType="locked"
        tooltip="You do not have permission to edit this row."
        disabled
      />
    );

    const clinicalApproverOnly = this.props.clinicalApproverOnly;

    return (
      <div>
        {clinicalApproverOnly && lockedBtn}
        {!clinicalApproverOnly && (
          <>
            <ActionButton
              actionType="note"
              actionColor={hasNotes ? "green" : "orange"}
              tooltip={hasNotes ? "Edit note" : "Create note"}
              onClick={() => this.toggleNoteModal(row)}
            />
            {!this.props.isDemoUser && (
              <>
                <ActionButton
                  onClick={() => this.toggleDeleteModal(row)}
                  actionType="delete"
                  actionColor="red"
                  tooltip="Delete row"
                />
              </>
            )}
          </>
        )}
      </div>
    );
  };

  getColumns = (editable) => {
    const isDemoUser = this.props.isDemoUser;

    const columns = [
      {
        dataField: "included",
        text: getDemoColumnHeader(
          OBFUSCATION_COLUMNS.INCLUDE,
          "Include?",
          isDemoUser,
        ),
        headerStyle: {
          ...HEADER_STYLES.optionalEdit,
          width: "5%",
        },
        headerAlign: "center",
        align: "center",
        formatter: isDemoUser
          ? obfuscationFormatter
          : this.fullYesNoIncludeFormatter,
        formatExtraData: OBFUSCATION_COLUMNS.INCLUDE,
        editorRenderer: this.dropdownRenderer,
        sort: true,
        headerAttrs: {
          title: COLUMN_LEGEND.OPTIONAL,
        },
      },
      {
        dataField: "ndc",
        sort: true,
        text: "NDC",
        editable: false,
        formatter: gpiNDCFormatter,
        formatExtraData: "ndc",
        headerStyle: {
          width: "6%",
        },
        headerAttrs: {
          title: COLUMN_LEGEND.MEDISPAN,
        },
      },
      {
        dataField: "multiSource",
        text: "MSC",
        editable: false,
        sort: true,
        headerAttrs: {
          title: COLUMN_LEGEND.MEDISPAN,
        },
      },
      {
        dataField: "ndcMedicationName",
        text: "Drug Name",
        editable: false,
        sort: true,
        headerAttrs: {
          title: COLUMN_LEGEND.MEDISPAN,
        },
        formatter: this.drugNameFormatter,
      },
      {
        dataField: "strength",
        text: getDemoColumnHeader(
          OBFUSCATION_COLUMNS.STRENGTH,
          "Strength per Unit",
          isDemoUser,
        ),
        headerStyle: HEADER_STYLES.optionalEdit,
        validator: floatMinGreater0Max99999MaxDec3Req,
        formatter: isDemoUser ? obfuscationFormatter : null,
        style: (cell, row, rowIndex, colIndex) => {
          if (isNaN(_.toNumber(cell))) {
            return {
              color: "red",
              fontWeight: "bold",
            };
          }
        },
        formatExtraData: OBFUSCATION_COLUMNS.STRENGTH,
        headerAttrs: {
          title: COLUMN_LEGEND.OPTIONAL,
        },
      },
      {
        dataField: "benchmarkDosage",
        text: getDemoColumnHeader(
          OBFUSCATION_COLUMNS.BENCHMARK,
          "Benchmark Dosage Strength",
          isDemoUser,
        ),
        headerStyle: HEADER_STYLES.requiredEdit,
        validator: floatMinGreater0Max99999MaxDec3Req,
        formatter: isDemoUser ? obfuscationFormatter : null,
        formatExtraData: OBFUSCATION_COLUMNS.BENCHMARK,
        headerAttrs: {
          title: COLUMN_LEGEND.REQUIRED,
        },
      },
      {
        dataField: "pairingCode",
        text: getDemoColumnHeader(OBFUSCATION_COLUMNS.PDC, "PDC", isDemoUser),
        sort: true,
        editable: false,
        formatter: isDemoUser ? obfuscationFormatter : null,
        formatExtraData: OBFUSCATION_COLUMNS.PDC,
      },
      {
        dataField: "type",
        text: getDemoColumnHeader(OBFUSCATION_COLUMNS.TAB, "T/A/B", isDemoUser),
        sort: true,
        editable: false,
        editorRenderer: this.dropdownRenderer,
        formatter: isDemoUser ? obfuscationFormatter : null,
        formatExtraData: OBFUSCATION_COLUMNS.TAB,
      },
      {
        dataField: "divisible",
        text: getDemoColumnHeader(
          OBFUSCATION_COLUMNS.DIVISIBLE,
          "Divisible",
          isDemoUser,
        ),
        formatter: isDemoUser ? obfuscationFormatter : fullYesNoFormatter,
        formatExtraData: OBFUSCATION_COLUMNS.DIVISIBLE,
        headerStyle: { ...HEADER_STYLES.requiredEdit, width: "5%" },
        editorRenderer: this.dropdownRenderer,
        headerAttrs: {
          title: COLUMN_LEGEND.REQUIRED,
        },
      },
      {
        dataField: "coursePackageSize",
        text: getDemoColumnHeader(
          OBFUSCATION_COLUMNS.PACKAGE_SIZE_PER_COURSE,
          "Pack Size per Course",
          isDemoUser,
        ),
        headerStyle: HEADER_STYLES.requiredEdit,
        validator: floatMin1Max99999MaxDec3Req,
        formatter: isDemoUser ? obfuscationFormatter : null,
        formatExtraData: OBFUSCATION_COLUMNS.PACKAGE_SIZE_PER_COURSE,
        headerAttrs: {
          title: COLUMN_LEGEND.REQUIRED,
        },
      },
      {
        dataField: "dosageUnitCount",
        text: getDemoColumnHeader(
          OBFUSCATION_COLUMNS.UNIT_PER_1_DOSE,
          "Units per 1 Dose",
          isDemoUser,
        ),
        headerStyle: HEADER_STYLES.requiredEdit,
        validator: floatMinGreater0Max99999MaxDec3Req,
        formatter: isDemoUser ? obfuscationFormatter : null,
        formatExtraData: OBFUSCATION_COLUMNS.UNIT_PER_1_DOSE,
        headerAttrs: {
          title: COLUMN_LEGEND.REQUIRED,
        },
      },
      {
        dataField: "dailyDoseCount",
        text: getDemoColumnHeader(
          OBFUSCATION_COLUMNS.DOSES_PER_DAY,
          "Doses per Day",
          isDemoUser,
        ),
        headerStyle: HEADER_STYLES.requiredEdit,
        validator: intMin1Max99Req,
        formatter: isDemoUser ? obfuscationFormatter : null,
        formatExtraData: OBFUSCATION_COLUMNS.DOSES_PER_DAY,
        headerAttrs: {
          title: COLUMN_LEGEND.REQUIRED,
        },
      },
      {
        dataField: "courseDoseDayCount",
        text: getDemoColumnHeader(
          OBFUSCATION_COLUMNS.ADMIN_DAYS_PER_COURSE,
          "Admin Days per Course",
          isDemoUser,
        ),
        headerStyle: HEADER_STYLES.requiredEdit,
        validator: intMin1Max99Req,
        formatter: isDemoUser ? obfuscationFormatter : null,
        formatExtraData: OBFUSCATION_COLUMNS.ADMIN_DAYS_PER_COURSE,
        headerAttrs: {
          title: COLUMN_LEGEND.REQUIRED,
        },
      },
      {
        dataField: "courseDayCount",
        text: getDemoColumnHeader(
          OBFUSCATION_COLUMNS.DAYS_PER_COURSE,
          "Days per Course",
          isDemoUser,
        ),
        headerStyle: HEADER_STYLES.requiredEdit,
        validator: intMin1Max999Req,
        formatter: isDemoUser ? obfuscationFormatter : null,
        formatExtraData: OBFUSCATION_COLUMNS.DAYS_PER_COURSE,
        headerAttrs: {
          title: COLUMN_LEGEND.REQUIRED,
        },
      },
      {
        dataField: "totalDailyDose",
        text: getDemoColumnHeader(
          OBFUSCATION_COLUMNS.TOTAL_DAILY_DOSE,
          "Total Daily Dose",
          isDemoUser,
        ),
        editable: false,
        headerStyle: HEADER_STYLES.calculated,
        formatter: isDemoUser ? obfuscationFormatter : null,
        formatExtraData: OBFUSCATION_COLUMNS.TOTAL_DAILY_DOSE,
        headerAttrs: {
          title: COLUMN_LEGEND.CALCULATED,
        },
      },
      {
        dataField: "doseStrength",
        text: getDemoColumnHeader(
          OBFUSCATION_COLUMNS.STRENGTH_PER_DOSE,
          "Strength per Dose",
          isDemoUser,
        ),
        editable: false,
        headerStyle: HEADER_STYLES.calculated,
        formatter: isDemoUser ? obfuscationFormatter : null,
        formatExtraData: OBFUSCATION_COLUMNS.STRENGTH_PER_DOSE,
        headerAttrs: {
          title: COLUMN_LEGEND.CALCULATED,
        },
      },
      {
        dataField: "standardDacon",
        text: "Standard DACon",
        editable: false,
        sort: true,
        headerStyle: HEADER_STYLES.calculated,
        headerAttrs: {
          title: COLUMN_LEGEND.CALCULATED,
        },
      },
      {
        dataField: "doseEquivalency",
        text: getDemoColumnHeader(
          OBFUSCATION_COLUMNS.DOSE_EQ,
          "Dose Equivalency",
          isDemoUser,
        ),
        editable: false,
        sort: true,
        headerStyle: HEADER_STYLES.calculated,
        formatter: isDemoUser ? obfuscationFormatter : null,
        formatExtraData: OBFUSCATION_COLUMNS.DOSE_EQ,
        headerAttrs: {
          title: COLUMN_LEGEND.CALCULATED,
        },
      },
      {
        dataField: "daconBracket",
        text: getDemoColumnHeader(
          OBFUSCATION_COLUMNS.DACON_BRACKET,
          "DACon Bracket",
          isDemoUser,
        ),
        formatter: isDemoUser ? obfuscationFormatter : fullYesNoFormatter,
        formatExtraData: OBFUSCATION_COLUMNS.DACON_BRACKET,
        headerStyle: { ...HEADER_STYLES.requiredEdit, width: "5%" },
        editorRenderer: this.dropdownRenderer,
        headerAttrs: {
          title: COLUMN_LEGEND.REQUIRED,
        },
      },
      {
        dataField: "uom",
        text: getDemoColumnHeader(OBFUSCATION_COLUMNS.UOM, "UOM", isDemoUser),
        editable: false,
        sort: true,
        formatter: isDemoUser ? obfuscationFormatter : null,
        formatExtraData: OBFUSCATION_COLUMNS.UOM,
        headerAttrs: {
          title: COLUMN_LEGEND.MEDISPAN,
        },
      },
      {
        dataField: "dosageForm",
        text: getDemoColumnHeader(
          OBFUSCATION_COLUMNS.DOSAGE_FORM,
          "Dosage Form",
          isDemoUser,
        ),
        editable: false,
        formatter: isDemoUser ? obfuscationFormatter : null,
        formatExtraData: OBFUSCATION_COLUMNS.DOSAGE_FORM,
        headerAttrs: {
          title: COLUMN_LEGEND.MEDISPAN,
        },
      },
      {
        dataField: "routeOfAdmin",
        text: getDemoColumnHeader(
          OBFUSCATION_COLUMNS.ROUTE_OF_ADMIN,
          "Route Admin",
          isDemoUser,
        ),
        editable: false,
        sort: true,
        formatter: isDemoUser ? obfuscationFormatter : null,
        formatExtraData: OBFUSCATION_COLUMNS.ROUTE_OF_ADMIN,
        headerAttrs: {
          title: COLUMN_LEGEND.MEDISPAN,
        },
      },
      {
        dataField: "adminType",
        text: "Provider or Self Administered",
        sort: true,
        editable: false,
        editorRenderer: this.dropdownRenderer,
      },
    ];

    if (
      this.props.editable &&
      this.props.isAuthorized &&
      !_.some(columns, { dataField: "actions" })
    ) {
      columns.unshift({
        dataField: "actions",
        text: "Action",
        formatter: this.actionFormatter,
        isDummyField: true,
        headerStyle: {
          width: "4%",
        },
        editable: false,
      });
    }
    return columns;
  };

  render = () => {
    if (_.isEmpty(this.props.ndcDPOs)) {
      return <h1>LOADING...</h1>;
    }

    const noteModalDPOisIncluded =
      this.state.noteModalDpo && this.state.noteModalDpo.included === "Y";

    return (
      <Row>
        <Col>
          <RPSDataGrid
            keyField="id"
            remote
            paginated
            sizePerPage={this.state.sizePerPage}
            page={this.state.currPage}
            totalSize={this.props.ndcDPOs.totalElements}
            data={this.props.ndcDPOs.content}
            columns={this.getColumns(this.props.editable)}
            onSizePerPageChange={this.onSizePerPageChange}
            onTableChange={this.handleTableChange}
            cellEditFactory={this.getCellEditFactory(this.props.editable)}
          />
        </Col>
        <ConfirmationModal
          isOpen={this.state.isDeleteOpen}
          toggle={this.toggleDeleteModal}
          header={this.state.dModalHeader}
          content={this.state.dModalContent}
          action={this.state.dModalAction}
          objId={this.state.selectedGridId}
        />
        <FormModal
          isOpen={this.state.isNoteModalOpen}
          toggle={this.toggleNoteModal}
          header={this.state.noteModalHeader ? this.state.noteModalHeader : ""}
          onSubmit={this.onNoteSubmit}
          usePristine={noteModalDPOisIncluded ? false : true}
          cancelCallback={() => {
            history.go(0);
          }}
        >
          <FormGroup>
            <Field
              id="notes"
              name="notes"
              type="textarea"
              label="Notes"
              required={
                this.state.noteModalDpo
                  ? this.state.noteModalDpo.included
                  : false
              }
              inputobj={{
                body: this.state.noteModalCurrentNote
                  ? this.state.noteModalCurrentNote
                  : "",
              }}
              onChange={this.onNoteChange}
              validate={noteModalDPOisIncluded ? [] : [required]}
              component={renderTextArea}
            />
          </FormGroup>
        </FormModal>
      </Row>
    );
  };
}

const mapStateToProps = (state) => {
  return { ndcDPOs: state.ndcDPOs.dpos };
};

export default connect(mapStateToProps, {
  getNDCDPOs,
  clearDPOS,
  updateDPO,
  deleteDPO,
})(NDCExpandRow);
