import {
  GET_AHFS_DRUG_DESCRIPTORS,
  CLEAR_AHFS_DRUG_DESCRIPTORS,
} from "../actions/types";

const ahfsDrugDescNamesReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_AHFS_DRUG_DESCRIPTORS:
      return action.payload;
    case CLEAR_AHFS_DRUG_DESCRIPTORS:
      return {};
    default:
      return state;
  }
};

export default ahfsDrugDescNamesReducer;
