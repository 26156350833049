import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getFilteredTargetCategorySummaries,
  populateBreadCrumbs,
  populateSidebar,
  getAlternateGpiCountsAction,
} from "../../../actions";
import { Row, Col, Button, Container } from "reactstrap";
import { Link } from "react-router-dom";
import "./TargetCategories.css";
import { FaCircle } from "react-icons/fa";
import _ from "lodash";
import RPSDataGrid from "../../DataGrid/RPSDataGrid";
import { Role } from "../../../utils/auth/roles";
import { getClinicalSidebarItems } from "../Constants";
import TargetCategoriesSearchForm from "./TargetCategoriesSearchForm";
import { DateTime } from "luxon";
import LoadingModal from "../../Modal/LoadingModal";

class TargetCategories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAuthorized: _.some(this.props.auth.currentUser.roles, (role) => {
        return (
          role.name === Role.CLINICAL_APPROVER.name ||
          role.name === Role.CLINICIAN.name
        );
      }),
      currPage: 1,
      sizePerPage: 15,
      totalSizePerPage: 15,
      searchTerms: {
        effectiveDate: DateTime.now().toISODate(),
        drugCategoryNames: [],
        drugNames: [],
      },
      isLoading: false,
      lModalContent: "",
    };
  }

  componentDidMount() {
    this.getPage(true);
    this.props.populateSidebar(
      getClinicalSidebarItems(this.props.auth.currentUser.roles),
    );
    this.setupBreadCrumbs();
  }

  componentDidUpdate() {
    this.setupBreadCrumbs();
  }

  setupBreadCrumbs = () => {
    const breadCrumbs = {
      crumbs: [{ to: "/dashboard", name: "Home", active: false }],
      finalCrumb: "Clinical Catalog",
    };
    this.props.populateBreadCrumbs(breadCrumbs);
  };

  getPairCount() {
    if (_.isEmpty(this.props.pairCounts)) {
      return "";
    } else {
      return this.props.pairCounts[0].pairCount.toLocaleString();
    }
  }

  getHeaderRow = () => {
    return (
      <Row className="target-categories-header">
        <Col>Drug Categories: {this.props.targetCategories.totalElements}</Col>
        <Col>Drug Pairs: {this.getPairCount()}</Col>
      </Row>
    );
  };

  linkFormatter = (content, row) => {
    const target = "/targetCategories/" + row.id + "/targetedGpis";

    return (
      <Button color="link" tag={Link} to={target} size="sm">
        {`${content}`}
      </Button>
    );
  };

  toggleLoadingModal = () => {
    const lModalContent = (
      <span>
        Compiling Catalog records for presentation in screen grid. This window will close when compiling is complete.
      </span>
    );
    this.setState({ isLoading: !this.state.isLoading, lModalContent });
  };

  getPage = async (initialLoad = false) => {
    if (!initialLoad) {
      this.toggleLoadingModal();
    }

    const summaryResult = await this.props.getFilteredTargetCategorySummaries(
      this.state.searchTerms,
      this.state.currPage - 1,
      this.state.sizePerPage,
      this.state.sortField,
    );

    const altCountsResult = await this.props.getAlternateGpiCountsAction(
      this.state.searchTerms.effectiveDate,
      "SINGLE",
    );

    if (!initialLoad && summaryResult && altCountsResult) {
      this.toggleLoadingModal();
    }
  };

  handleTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
    if (type === "sort") {
      sortField = {
        dataField: sortField,
        order: sortOrder,
      };
      this.setState({ sortField }, () => {
        this.getPage();
      });
      return -1;
    }

    this.setState({ currPage: page, sizePerPage: sizePerPage }, () => {
      this.getPage();
    });
  };

  onSizePerPageChange = (sizePerPage, page) => {
    this.getPage(page, sizePerPage, this.props.sorts);
  };

  onSearchSubmit = (searchTerms) => {
    this.setState({ currPage: 1, searchTerms }, () => {
      this.getPage();
    });
  };

  columns = [
    {
      dataField: "categoryDescription",
      text: "Drug Category",
      sort: true,
      formatter: this.linkFormatter,
    },
    {
      dataField: "clinicalImpact",
      text: "Clinical Impact",
      sort: true,
      formatter: (_, row) => {
        const className =
          row.clinicalImpact === "RED"
            ? "clinicalimpact-red"
            : "clinicalimpact-green";
        return (
          <FaCircle
            className={className}
            title={row.clinicalImpactDescription}
          />
        );
      },
    },
    {
      dataField: "totalCnt",
      text: "Target Count",
      sort: true,
    },
  ];

  render() {
    if (_.isEmpty(this.props.targetCategories)) {
      return <div>Loading...</div>;
    }

    return (
      <Container className="main-container" fluid>
        <Row className="mt-3">
          <Col>
            <TargetCategoriesSearchForm
              onSubmit={this.onSearchSubmit}
              searchTerms={this.state.searchTerms}
            />
          </Col>
        </Row>

        {this.getHeaderRow()}
        <Row>
          <Col>
            <RPSDataGrid
              className="react-bootstrap-table"
              keyField="id"
              paginated
              remote
              sizePerPage={this.state.sizePerPage}
              page={this.state.currPage}
              totalSize={this.props.targetCategories.totalElements}
              data={this.props.targetCategories.content}
              columns={this.columns}
              onSizePerPageChange={this.onSizePerPageChange}
              onTableChange={this.handleTableChange}
            />
          </Col>
        </Row>
        <LoadingModal
          isOpen={this.state.isLoading}
          toggle={this.toggleLoadingModal}
          header="Compiling Catalog Records"
          content={this.state.lModalContent}
        />
      </Container>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    auth: state.auth,
    targetCategories: state.targetCategorySummaries,
    pairCounts: state.alternateGpis.counts,
  };
};

export default connect(mapStateToProps, {
  getFilteredTargetCategorySummaries,
  populateBreadCrumbs,
  populateSidebar,
  getAlternateGpiCountsAction,
})(TargetCategories);
