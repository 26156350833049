export const MINIMUM_VALUE = {
  MINIMUM_ONE: "Minimum of 1",
  NO_MINIMUM: "No Minimum",
  PER_GPI: "See GPI",
};

export const minimumValues = [
  { key: 1, value: "MINIMUM_ONE", label: MINIMUM_VALUE.MINIMUM_ONE },
  { key: 2, value: "NO_MINIMUM", label: MINIMUM_VALUE.NO_MINIMUM },
  { key: 3, value: "PER_GPI", label: MINIMUM_VALUE.PER_GPI },
];

export const MIN_ONE_TIP =
  "Min of 1:  When the Fill Factor is <1 the Category and GPI values will be set to a minimum value of 1.00.";
export const NO_MIN_TIP =
  "No Min: When the Fill Factor is <1 the Category and GPI values remain as decimal less than 1.";
export const SEE_GPI_TIP =
  "See GPI: GPI Fill Factors are defined within Clinical Decision Grids.";

export const DC_COL_TOOLTIPS = {
  CLINICAL_IMPACT:
    "Green indicates higher and Gray indicates lower impacts of the Drug Category.",
  CONDITION_CODE: "The medical conditions that the Drug Category.",
  DRUG_CATEGORY:
    "The Drug Category identifies a list of drug products. These products are managed in Clinical Decision Grids.",
  MINIMUM_VALUE: `${MIN_ONE_TIP} \n\n${NO_MIN_TIP} \n\n${SEE_GPI_TIP}`,
  RETIRED: "Yes indicates the Drug Category is retired and can’t not be used.",
  UPDATED_DATE: "The date the Drug Category was last updated.",
};
