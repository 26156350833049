import { getClinicalBackend } from "../../api/clinicalBackend";
import { handleError, formatPageableParameters } from "../../utils";
import { tryRefreshToken } from "../auth/authAction";
import {
  GET_CLINICAL_IMPACT_CODES,
  GET_CONDITION_CODES,
  GET_DRUG_CATEGORY,
  GET_DRUG_CATEGORIES,
  GET_DRUG_CATEGORIES_AUDIT_LOG,
  CLEAR_DRUG_CATEGORY,
} from "../types";
import history from "../../history";

export const getDrugCategory = (id) => async (dispatch, getState) => {
  try {
    dispatch(tryRefreshToken());
    const authToken = getState().auth.authToken;
    const endpoint = `/api/drug-categories/${id}`;
    const category = await getClinicalBackend(authToken).get(endpoint);
    dispatch({
      type: GET_DRUG_CATEGORY,
      payload: category.data,
    });
  } catch (err) {
    handleError(err.response, dispatch);
  }
};

export const clearDrugCategory = () => (dispatch) => {
  dispatch({ type: CLEAR_DRUG_CATEGORY });
};

export const getDrugCategories = () => async (dispatch, getState) => {
  try {
    dispatch(tryRefreshToken());
    const authToken = getState().auth.authToken;
    const endpoint = "/api/drug-categories/flat";
    const categories = await getClinicalBackend(authToken).get(endpoint);
    dispatch({
      type: GET_DRUG_CATEGORIES,
      payload: categories.data,
    });
  } catch (err) {
    handleError(err.response, dispatch);
  }
};

export const trySaveDrugCategory =
  (formValues) => async (dispatch, getState) => {
    try {
      dispatch(tryRefreshToken());
      const authToken = getState().auth.authToken;
      const endpoint = "/api/drug-categories";

      await getClinicalBackend(authToken).post(
        endpoint,
        JSON.stringify(formValues)
      );

      history.go(0);
    } catch (err) {
      handleError(err.response, dispatch);
    }
  };

export const tryUpdateDrugCategory =
  (formValues) => async (dispatch, getState) => {
    try {
      dispatch(tryRefreshToken());
      const authToken = getState().auth.authToken;
      const endpoint = `/api/drug-categories/${formValues.id}`;

      await getClinicalBackend(authToken).put(
        endpoint,
        JSON.stringify(formValues)
      );

      history.go(0);
    } catch (err) {
      handleError(err.response, dispatch);
    }
  };

export const getCategoryCodes =
  (forCondition) => async (dispatch, getState) => {
    try {
      dispatch(tryRefreshToken());
      const authToken = getState().auth.authToken;
      const type = forCondition ? "COND" : "CATE";
      const subType = forCondition ? "COND" : "IMPA";
      const endpoint = `/api/codes/?type=${type}&subtype=${subType}`;
      const codes = await getClinicalBackend(authToken).get(endpoint);
      dispatch({
        type: forCondition ? GET_CONDITION_CODES : GET_CLINICAL_IMPACT_CODES,
        payload: codes.data,
      });
    } catch (err) {
      handleError(err.response, dispatch);
    }
  };

export const getDrugCategoriesAuditLog =
  (searchTerms, pageNumber, size, sorts) => async (dispatch, getState) => {
    try {
      dispatch(tryRefreshToken());
      const authToken = getState().auth.authToken;
      const endPoint = `/api/drug-category-audits?${formatPageableParameters(
        pageNumber,
        size,
        sorts ? [sorts] : null
      )}`;

      const auditLogs = await getClinicalBackend(authToken).post(
        endPoint,
        JSON.stringify(searchTerms)
      );

      dispatch({
        type: GET_DRUG_CATEGORIES_AUDIT_LOG,
        payload: auditLogs.data,
      });
    } catch (err) {
      handleError(err.response, dispatch);
    }
  };
