import {
  GET_DRUG_CATEGORY,
  GET_DRUG_CATEGORIES,
  GET_CLINICAL_IMPACT_CODES,
  GET_CONDITION_CODES,
  GET_DRUG_CATEGORIES_AUDIT_LOG,
  CLEAR_DRUG_CATEGORY,
} from "../actions/types";

const initialState = {
  categories: [],
  clinicalImpactCodes: [],
  conditionCodes: [],
  auditLogs: [],
};

const drugCategoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_DRUG_CATEGORY:
      return { ...state, category: action.payload };
    case GET_DRUG_CATEGORIES:
      return { ...state, categories: action.payload };
    case GET_CLINICAL_IMPACT_CODES:
      return { ...state, clinicalImpactCodes: action.payload };
    case GET_CONDITION_CODES:
      return { ...state, conditionCodes: action.payload };
    case GET_DRUG_CATEGORIES_AUDIT_LOG:
      return { ...state, auditLogs: action.payload };
    case CLEAR_DRUG_CATEGORY:
      return { ...state, category: undefined };
    default:
      return state;
  }
};

export default drugCategoryReducer;
