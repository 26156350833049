import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import authReducer from "./authReducer";
import catalogReducer from "./catalogReducer";
import catalogsReducer from "./catalogsReducer";
import targetCategorySummariesReducer from "./targetCategorySummariesReducer";
import targetGpiReducer from "./targetGpiReducer";
import eqGroupsReducer from "./eqGroupsReducer";
import eqDrugsReducer from "./eqDrugsReducer";
import clientsReducer from "./clientsReducer";
import mpdTieInsReducer from "./mpdTieInsReducer";
import contractsReducer from "./contractsReducer";
import linesOfBusinessReducer from "./linesOfBusinessReducer";
import clientConfigFilesReducer from "./clientConfigFilesReducer";
import clientConfigFilesPageableReducer from "./clientConfigFilesPageableReducer";
import awdpFileContentReducer from "./awdpFileContentReducer";
import macFileContentReducer from "./macFileContentReducer";
import formFileContentReducer from "./formFileContentReducer";
import bcFileContentReducer from "./bcFileContentReducer";
import dataGridSortReducer from "./dataGridSortReducer";
import dataGridSearchReducer from "./dataGridSearchReducer";
import gpFileContentReducer from "./gpFileContentReducer";
import sidebarReducer from "./sidebarReducer";
import breadcrumbReducer from "./breadcrumbReducer";
import benefitsReducer from "./benefitsReducer";
import ahfsGpisReducer from "./ahfsGpisReducer";
import ahfsCategoriesReducer from "./ahfsCategoriesReducer";
import ahfsDrugDescNamesReducer from "./ahfsDrugDescNamesReducer";
import webpagePropertyReducer from "./webpagePropertyReducer";
import mpdReducer from "./mpdReducer";
import ndcPricesReducer from "./ndcPricesReducer";
import userProfileReducer from "./userProfileReducer";
import mpdCalculationReducer from "./mpdCalculationReducer";
import errorReducer from "./errorReducer";
import systemCodesCatReducer from "./systemCodesCatReducer";
import systemCodesGpiReducer from "./systemCodesGpiReducer";
import mpdSummariesReducer from "./mpdSummariesReducer";
import mpdsLoadedReducer from "./mpdsLoadedReducer";
import benefitsLoadedReducer from "./benefitsLoadedReducer";
import mpdTargetCostsReducer from "./mpdTargetCostsReducer";
import drugPairFiltersReducer from "./drugPairFiltersReducer";
import claimsReducer from "./claimsReducer";
import claimsDateRangeReducer from "./reportClaimsDateRangeReducer";
import rollinsReducer from "./rollinsReducer";
import systemCodesDrugPairErrorsReducer from "./systemCodesDrugPairErrorsReducer";
import healthCheckReducer from "./healthCheckReducer";
import eligibilityReducer from "./eligibilityReducer";
import mpdActivityLogReducer from "./mpdActivityLogReducer";
import productsReducer from "./productsReducer";
import stackDetailsReducer from "./stackDetailsReducer";
import reportTypesReducer from "./reportTypesReducer";
import claimCorrelationsReducer from "./claimCorrelationsReducer";
import estimatedClaimCorrelationsReducer from "./estimatedClaimCorrelationsReducer";
import clientGroupReducer from "./clientGroupReducer";
import reportsReducer from "./reportsReducer";
import cagcentricCountsReducer from "./cagcentricCountsReducer";
import decisionGridsReducer from "./decisionGridsReducer";
import drugCategoryReducer from "./drugCategoryReducer";
import drugPairingOptionsReducer from "./drugPairingOptionsReducer";
import workbooksReducer from "./workbooksReducer";
import workbookEntryReducer from "./workbookEntryReducer";
import workbookErrorReducer from "./workbookErrorReducer";
import catalogDeliveriesReducer from "./catalogDeliveriesReducer";
import additionalTargetDrugReducer from "./additionalTargetDrugReducer";
import uploadedRollinsReportsReducer from "./uploadedRollinsReportsReducer";
import rollinsReportNotificationsReducer from "./rollinsReportNotificationsReducer";
import productNotificationsReducer from "./productNotificationsReducer";
import weekOfYearListReducer from "./weekOfYearListReducer";
import mpdCategoryExclusionsReducer from "./mpdCategoryExclusionsReducer";
import glossaryTermsReducer from "./glossaryTermsReducer";
import fillFactorsBaselineDatesReducer from "./fillFactorsBaselineDatesReducer";
import fillFactorsReducer from "./fillFactorsReducer";
import fillFactorsUpdateReducer from "./fillFactorsUpdateReducer";
import categoryReferenceReducer from "./categoryReferenceReducer";
import clientContractProductReducer from "./clientContractProductReducer";
import loadingReducer from "./loadingReducer";
import additionalTargetDrugAuditLogReducer from "./additionalTargetDrugAuditLogReducer";
import categoryReferenceDrugAuditLogReducer from "./categoryReferenceDrugAuditLogReducer";
import rebateReferenceNdcReducer from "./rebateReferenceNdcReducer";
import prospectiveSavingsReducer from "./prospectiveSavingsReducer";
import prospectiveSavingsLoadedReducer from "./prospectiveSavingsLoadedReducer";
import workbookApprovalReducer from "./workbookApprovalReducer";
import alternateGpiReducer from "./alternateGpiReducer";
import ndcDrugPairingOptionsReducer from "./ndcDrugPairingOptionsReducer";
import internalCatalogReportsReducer from "./internalCatalogReportsReducer";

export default combineReducers({
  auth: authReducer,
  form: formReducer,
  catalog: catalogReducer,
  catalogs: catalogsReducer,
  targetCategorySummaries: targetCategorySummariesReducer,
  ahfsCategories: ahfsCategoriesReducer,
  gpis: targetGpiReducer,
  eqgroups: eqGroupsReducer,
  eqdrugs: eqDrugsReducer,
  benefits: benefitsReducer,
  benefitsLoaded: benefitsLoadedReducer,
  clients: clientsReducer,
  mpdTieIns: mpdTieInsReducer,
  selectedContract: contractsReducer,
  linesOfBusiness: linesOfBusinessReducer,
  clientConfigFiles: clientConfigFilesReducer,
  clientConfigFilesPageable: clientConfigFilesPageableReducer,
  awdpFileContent: awdpFileContentReducer,
  macFileContent: macFileContentReducer,
  gpFileContent: gpFileContentReducer,
  formFileContent: formFileContentReducer,
  bcFileContent: bcFileContentReducer,
  dataGridSorts: dataGridSortReducer,
  dataGridSearchTerm: dataGridSearchReducer,
  sidebar: sidebarReducer,
  breadcrumbs: breadcrumbReducer,
  ahfsGpis: ahfsGpisReducer,
  ahfsDrugDescriptorNames: ahfsDrugDescNamesReducer,
  webpageProperties: webpagePropertyReducer,
  sidebarCheckboxChange: sidebarReducer,
  mpds: mpdReducer,
  mpdsLoaded: mpdsLoadedReducer,
  mpdSummaries: mpdSummariesReducer,
  ndcPrices: ndcPricesReducer,
  passwordChange: userProfileReducer,
  mpdCalculations: mpdCalculationReducer,
  error: errorReducer,
  exclusionReasons: systemCodesCatReducer,
  exclusionGPIReasons: systemCodesGpiReducer,
  mpdTargetCosts: mpdTargetCostsReducer,
  drugPairFilters: drugPairFiltersReducer,
  claimCorrelations: claimCorrelationsReducer,
  estimatedClaimCorrelations: estimatedClaimCorrelationsReducer,
  claims: claimsReducer,
  reportClaimsDateRange: claimsDateRangeReducer,
  rollIns: rollinsReducer,
  fillFactorsBaselineDates: fillFactorsBaselineDatesReducer,
  fillFactors: fillFactorsReducer,
  fillFactor: fillFactorsUpdateReducer,
  drugPairErrorCodes: systemCodesDrugPairErrorsReducer,
  healthCheck: healthCheckReducer,
  eligibilityCorrelations: eligibilityReducer,
  mpdActivity: mpdActivityLogReducer,
  products: productsReducer,
  stackDetails: stackDetailsReducer,
  reportTypes: reportTypesReducer,
  clientGroups: clientGroupReducer,
  reports: reportsReducer,
  cagcentricCounts: cagcentricCountsReducer,
  decisionGrids: decisionGridsReducer,
  drugCategories: drugCategoryReducer,
  drugPairingOptions: drugPairingOptionsReducer,
  workbooks: workbooksReducer,
  workbookEntries: workbookEntryReducer,
  workbookErrors: workbookErrorReducer,
  catalogDeliveries: catalogDeliveriesReducer,
  additionalTargetDrugs: additionalTargetDrugReducer,
  uploadedRollinsReports: uploadedRollinsReportsReducer,
  rollinsReportNotifications: rollinsReportNotificationsReducer,
  productNotifications: productNotificationsReducer,
  weekOfYearList: weekOfYearListReducer,
  mpdCategoryExclusions: mpdCategoryExclusionsReducer,
  glossaryTerms: glossaryTermsReducer,
  categoryRefs: categoryReferenceReducer,
  clientContractProductAuditLogs: clientContractProductReducer,
  loadingStatus: loadingReducer,
  additionalTargetDrugAuditLogs: additionalTargetDrugAuditLogReducer,
  categoryReferenceDrugAuditLogs: categoryReferenceDrugAuditLogReducer,
  rebateReferenceNdcs: rebateReferenceNdcReducer,
  prospectiveSavingsReports: prospectiveSavingsReducer,
  prospectiveSavingsReportsLoaded: prospectiveSavingsLoadedReducer,
  workbookApproval: workbookApprovalReducer,
  alternateGpis: alternateGpiReducer,
  ndcDPOs: ndcDrugPairingOptionsReducer,
  internalCatalogReports: internalCatalogReportsReducer,
});
