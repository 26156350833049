import React, { Component } from "react";
import { change, Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { Button, Form, Label, Input, Container, Row, Col } from "reactstrap";
import "./Login.css";
import {
  showAuthError,
  trySignIn,
  trySignOut,
  toggleSidebarVisibility,
  tryHealthCheck,
} from "../../actions";
import { populateBreadCrumbs } from "../../actions";
import AlertModal from "../Modal/AlertModal";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: this.props.hasAuthError,
    };
    this.toggleModal = this.toggleModal.bind(this);
  }

  toggleModal = () => {
    this.setState({
      isOpen: !this.props.hasAuthError,
    });
  };

  modalAction = () => {
    this.props.showAuthError(false);
  };

  setupBreadCrumbs = () => {
    const breadCrumbs = {
      crumbs: [{ to: "", name: "", active: false }],
      finalCrumb: "",
    };
    this.props.populateBreadCrumbs(breadCrumbs);
  };

  componentDidMount() {
    this.props.tryHealthCheck();
    this.props.toggleSidebarVisibility();
    this.setState({ isOpen: this.props.hasAuthError });

    this.setupBreadCrumbs();

    if (this.props.location.state && this.props.location.state.timeout) {
      this.props.trySignOut(true);
    }
  }

  componentWillUnmount() {
    this.props.toggleSidebarVisibility();
  }

  renderInput({ input, id, placeholder, required, type }) {
    return (
      <div className="field">
        <Label for={id} className="visually-hidden">
          {placeholder}
        </Label>
        <Input
          type={type}
          id={id}
          placeholder={placeholder}
          required={required}
          {...input}
        />
      </div>
    );
  }

  onSubmit = (formValues) => {
    this.props.trySignIn(formValues);
  };

  render() {
    return (
      <Container className="main-container">
        <Row>
          <Col className="text-center">
            <h1>AR-TAS System</h1>
          </Col>
        </Row>
        {this.props.timedOut && (
          <Row>
            <Col className="timeout-message text-center">
              <h3>You were logged out due to inactivity.</h3>
            </Col>
          </Row>
        )}
        <Row>
          <Col>
            <Form
              onSubmit={this.props.handleSubmit(this.onSubmit)}
              className="form-signin"
              autoComplete="off"
            >
              <h1 className="h3 mb-3 font-weight-normal">Sign in</h1>

              <Field
                name="username"
                type="email"
                id="inputEmail"
                placeholder="User Name"
                required
                component={this.renderInput}
              />
              <Field
                name="password"
                type="password"
                id="inputPassword"
                placeholder="Password"
                required
                component={this.renderInput}
              />

              <Button
                color="primary"
                size="lg"
                type="submit"
                block
                disabled={this.props.healthCheckFailed}
              >
                Submit
              </Button>
            </Form>
          </Col>
        </Row>
        <AlertModal
          isOpen={this.props.hasAuthError}
          toggle={this.toggleModal}
          header="Authentication Error"
          content="Invalid User Name or Password."
          action={this.modalAction}
        />
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    timedOut: state.auth.timedOut,
    hasAuthError: state.auth.hasAuthError,
    healthCheckFailed: !state.healthCheck.healthy,
  };
};

// Clear the password field when form is submitted
const postSubmit = (result, dispatch) => {
  dispatch(change("loginForm", "password", ""));
};

const formWrapped = reduxForm({
  form: "loginForm",
  onSubmitSuccess: postSubmit,
})(Login);

export default connect(mapStateToProps, {
  showAuthError,
  trySignIn,
  trySignOut,
  toggleSidebarVisibility,
  populateBreadCrumbs,
  tryHealthCheck,
})(formWrapped);
